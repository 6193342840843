import React, { useState } from 'react'
import { SessionData, UserData } from '../../data/types'
import './reports.css'
import { Slider } from '../misc/slider'
import ReportTile from './reportTile'

interface ReportProps {
    sessions: SessionData[]
    user: UserData
}

const Report: React.FC<ReportProps> = ({ sessions, user }) => {
    const [isGenerationAttributions, setIsGenerationAttributions] = useState<boolean>(false)
    const [isAttributions, setIsAttributions] = useState<boolean>(false)
    const [isDisplayBugList, setIsDisplayBugList] = useState<boolean>(false)
    const [isLocalTime, setIsLocalTime] = useState<boolean>(false)
    const [isDescendingEventOrder, setIsDescendingEventOrder] = useState<boolean>(false)
    const [isAccumulator, setIsAccumulator] = useState<boolean>(false)

    const [selectedSessionId, setSelectedSessionId] = useState<string>('')
    const [selectedSession, setSelectedSession] = useState<SessionData | null>(null)

    const handleGenerateClick = () => {
        const selectedSession = sessions.find((session) => session.id === selectedSessionId)
        if (selectedSession) {
            setSelectedSession(selectedSession)
        }
    }

    return (
        <div className="session-tile-wrapper">
            <div className="session-list">
                <div className="toolbar">
                    <div className="tool">
                        <div className="label">Generation Attributions</div>
                        <Slider
                            enabled={isGenerationAttributions}
                            setEnabled={setIsGenerationAttributions}
                        />
                    </div>
                    <div className="tool">
                        <div className="label">Attributions</div>
                        <Slider enabled={isAttributions} setEnabled={setIsAttributions} />
                    </div>
                    <div className="tool">
                        <div className="label">Event List</div>
                        <Slider enabled={isDisplayBugList} setEnabled={setIsDisplayBugList} />
                    </div>
                    <div className="tool">
                        <div className="label">Accumulation View</div>
                        <Slider enabled={isAccumulator} setEnabled={setIsAccumulator} />
                    </div>
                    <div className="tool">
                        <div className="label">Descending Event Order</div>
                        <Slider
                            enabled={isDescendingEventOrder}
                            setEnabled={setIsDescendingEventOrder}
                        />
                    </div>
                    <div className="tool">
                        <div className="label">Local time</div>
                        <Slider enabled={isLocalTime} setEnabled={setIsLocalTime} />
                    </div>
                </div>
                <div className="machines-container">
                    <select
                        value={selectedSessionId}
                        onChange={(e) => setSelectedSessionId(e.target.value)}
                        className="session-select"
                    >
                        <option value="" disabled>
                            Select a session
                        </option>
                        {sessions.map((session, index) => (
                            <option key={index} value={session.id}>
                                {session.title}
                            </option>
                        ))}
                    </select>
                    {sessions.length === 0 && <div>No Recorded Sessions</div>}
                    <div className="generate-button">
                        <button onClick={handleGenerateClick} disabled={!selectedSessionId}>
                            Generate
                        </button>
                    </div>
                </div>
                {selectedSession && (
                    <ReportTile
                        session={selectedSession}
                        isGenerationAttributions={isGenerationAttributions}
                        isAttributions={isAttributions}
                        user={user}
                        isDisplayBugList={isDisplayBugList}
                        isAccumulator={isAccumulator}
                        isDescending={isDescendingEventOrder}
                        isUtc={!isLocalTime}
                    />
                )}
            </div>
        </div>
    )
}

export default Report
