import { sha256 } from 'js-sha256'

export const generateRandomString = (length: number): string => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length)
        result += charset[randomIndex]
    }
    return result
}

export const generateCodeChallenge = (verifier: string): string => {
    return sha256(verifier)
}

interface AuthParams {
    code_verifier: string
    code_challenge: string
    state: string
    nonce: string
}

export const storeAuthParams = (params: AuthParams): void => {
    Object.keys(params).forEach((key) => {
        localStorage.setItem(key, params[key as keyof AuthParams])
    })
}

export const clearAuthParams = (): void => {
    localStorage.removeItem('code_verifier')
    localStorage.removeItem('code_challenge')
    localStorage.removeItem('state')
    localStorage.removeItem('nonce')
}

const initializeAuthParams = (): AuthParams => {
    const code_verifier = generateRandomString(32)
    const code_challenge = generateCodeChallenge(code_verifier)
    const state = generateRandomString(16)
    const nonce = generateRandomString(16)
    const params: AuthParams = { code_verifier, code_challenge, state, nonce }
    storeAuthParams(params)
    return params
}

export const getAuthParams = (): AuthParams => {
    let code_verifier = localStorage.getItem('code_verifier')
    let code_challenge = localStorage.getItem('code_challenge')
    let state = localStorage.getItem('state')
    let nonce = localStorage.getItem('nonce')

    if (!code_verifier || !code_challenge || !state || !nonce) {
        return initializeAuthParams()
    }

    return { code_verifier, code_challenge, state, nonce } as AuthParams
}
