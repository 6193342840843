import {
    MachineData,
    ImplementData,
    SessionData,
    SnapshotData,
    SoftwareVersions,
    UserData,
} from '../../data/types'

const sanitizeUserData = (user: any): UserData => ({
    id: user.id,
    given_name: user.given_name,
    family_name: user.family_name,
    groups: user.groups,
    roles: user.roles,
})

const sanitizeSoftwareVersion = (softwareVersion: any): SoftwareVersions => ({
    name: softwareVersion.name,
    version: softwareVersion.version,
})

const sanitizeMachineData = (machine: any): MachineData => ({
    id: machine.id,
    name: machine.name,
    description: machine.description || '',
    softwareVersions: machine.softwareVersions.map(sanitizeSoftwareVersion),
})

const sanitizeImplementData = (implement: any): ImplementData => ({
    id: implement.id,
    name: implement.name,
    description: implement.description || '',
    softwareVersions: implement.softwareVersions.map(sanitizeSoftwareVersion),
})

const sanitizeSnapshotData = (snapshot: any): SnapshotData => ({
    id: snapshot.id,
    initiationTimestamp: new Date(snapshot.initiationTimestamp).toISOString(),
    submittedTimestamp: new Date(snapshot.submittedTimestamp).toISOString(),
    description: snapshot.description,
    creator: snapshot?.creator,
    images: snapshot.images || [],
})

const sanitizeSessionData = (session: any): SessionData => ({
    id: session.id,
    title: session.title,
    startTime: new Date(session.startTime).toISOString(),
    stopTime: session.stopTime ? new Date(session.stopTime).toISOString() : undefined,
    owner: sanitizeUserData(session.owner),
    machine: session.machine, // ? sanitizeMachineData(session.machine) : undefined,
    implement: session.implement, //sanitizeImplementData(session.implement),
    softwareVersions: {
        machines: session.softwareVersions.machines.map(sanitizeSoftwareVersion),
        _implements: session.softwareVersions._implements.map(sanitizeSoftwareVersion),
    },
    finalSoftwareVersions: {
        machines: session.finalSoftwareVersions?.machines.map(sanitizeSoftwareVersion),
        _implements: session.finalSoftwareVersions?._implements.map(sanitizeSoftwareVersion),
    },
    snapshots: session.snapshots.map((snapshot: any) => sanitizeSnapshotData(snapshot)),
    active: session.active,
})

const sanitizeImplementsData = (implementList: any[]): ImplementData[] => {
    return implementList.map(sanitizeImplementData)
}

const sanitizeMachinesData = (machines: any[]): MachineData[] => {
    return machines.map(sanitizeMachineData)
}

const sanitizeSessionsData = (sessions: any[]): SessionData[] => {
    return sessions.map(sanitizeSessionData)
}

export {
    sanitizeSessionsData,
    sanitizeSessionData,
    sanitizeSnapshotData,
    sanitizeMachinesData,
    sanitizeMachineData,
    sanitizeImplementData,
    sanitizeImplementsData,
}
