import { useState } from 'react'
import { MachineData, SoftwareVersions } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

interface MachineTilesProps {
    machines: MachineData[]
    onDeleteMachine: (machine_id: string) => Promise<void>
    onEditMachine: (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => Promise<void>
}

function MachineTiles({ machines, onDeleteMachine, onEditMachine }: MachineTilesProps) {
    const [editingMachine, setEditingMachine] = useState<string | null>(null)
    const [machineName, setMachineName] = useState<string>('')
    const [machineDescription, setMachineDescription] = useState<string>('')
    const [machineSoftware, setMachineSoftware] = useState<SoftwareVersions[]>([])

    const startEditing = (machine: MachineData) => {
        setEditingMachine(machine.id)
        setMachineName(machine.name)
        setMachineDescription(machine.description || '')
        setMachineSoftware(machine.softwareVersions)
    }

    const handleEditMachine = async (id: string) => {
        await onEditMachine(id, machineName, machineDescription || '', machineSoftware)
        setEditingMachine(null)
    }

    const cancelEditingMachine = () => {
        setEditingMachine(null)
    }

    const handleSoftwareVersionChange = (index: number, value: string) => {
        setMachineSoftware((prevSoftware) =>
            prevSoftware.map((software, i) =>
                i === index ? { ...software, version: value } : software
            )
        )
    }

    return (
        <>
            <header>Existing Vehicles</header>
            <div className="session-tile">
                {machines.map((machine) => (
                    <div key={machine.id} className="job-tile">
                        <div className="tile-header">
                            <div className="title">
                                {editingMachine === machine.id ? (
                                    <input
                                        type="text"
                                        value={machineName}
                                        onChange={(e) => setMachineName(e.target.value)}
                                    />
                                ) : (
                                    machine.name
                                )}
                            </div>
                            <div className="controls">
                                {editingMachine === machine.id ? (
                                    <>
                                        <button
                                            className="edit-button"
                                            title="Save Machine"
                                            onClick={() => handleEditMachine(machine.id)}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className="edit-button"
                                            title="Save Machine"
                                            onClick={() => cancelEditingMachine()}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className="edit-button"
                                            title="Edit Machine"
                                            onClick={() => startEditing(machine)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button
                                            className="delete-button"
                                            title="Delete Machine"
                                            onClick={() => onDeleteMachine(machine.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="body">
                            <div className="description">
                                {editingMachine === machine.id ? (
                                    <div className="software-version-editor">
                                        <span style={{ fontWeight: '600' }}>Software Versions</span>
                                        <div className="input-group">
                                            {machineSoftware.map((version, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div>{version.name}: </div>
                                                        <input
                                                            value={version.version}
                                                            onChange={(e) =>
                                                                handleSoftwareVersionChange(
                                                                    index,
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <span style={{ fontWeight: '600' }}>Software Versions</span>
                                        <div>
                                            {machine.softwareVersions.map((version, index) => {
                                                return (
                                                    <div key={index}>
                                                        {version.name}: {version.version}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}

                                <div>
                                    <div style={{ fontWeight: '600' }}>Description</div>
                                    {editingMachine === machine.id ? (
                                        <div className="text-area-wrapper">
                                            <textarea
                                                value={machineDescription}
                                                onChange={(e) =>
                                                    setMachineDescription(e.target.value)
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <span
                                                style={{
                                                    fontWeight: '300',
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                            >
                                                {machine.description}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default MachineTiles
