import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, GroupsData, ErrorPayload } from '../types'

const initialState: DataState<GroupsData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

const sortAlphabetically = (array: string[]) => {
    try {
        return [...array].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    } catch (error) {
        console.log(error)
        return array
    }
}

const alphabetizeSoftwareItems = (groupsData: GroupsData[]) => {
    return groupsData.map((group) => {
        if (group.reporterProfile && group.reporterProfile.loggingConfiguration?.softwareItems) {
            const sortedMachines = sortAlphabetically(
                group.reporterProfile.loggingConfiguration?.softwareItems.machines
            )
            const sortedImplements = sortAlphabetically(
                group.reporterProfile.loggingConfiguration?.softwareItems._implements
            )

            return {
                ...group,
                reporterProfile: {
                    ...group.reporterProfile,
                    softwareItems: {
                        ...group.reporterProfile.loggingConfiguration?.softwareItems,
                        machines: sortedMachines,
                        _implements: sortedImplements,
                    },
                },
            }
        }
        return group
    })
}

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        // Start & Error handlers
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },

        // Result handlers
        fetchGroupsSuccess(state, action: PayloadAction<GroupsData[]>) {
            state.loading = false
            state.data = alphabetizeSoftwareItems(action.payload)
            state.error.message = ''
        },
    },
})

export const { fetchDataRequest, fetchDataFailure, fetchGroupsSuccess } = groupsSlice.actions
export default groupsSlice.reducer
