import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, UserData, ErrorPayload } from '../types'

const initialState: DataState<UserData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

const usersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // Start & Error handlers
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },

        // Result handlers
        fetchUserSuccess(state, action: PayloadAction<UserData>) {
            state.loading = false
            state.data = [action.payload]
            state.error.message = ''
        },
    },
})

export const { fetchDataRequest, fetchDataFailure, fetchUserSuccess } = usersSlice.actions
export default usersSlice.reducer
