import { io } from 'socket.io-client'

const URL =
    window.location.hostname === 'localhost'
        ? 'http://localhost:8000'
        : 'https://fieldeventreporter.com:8000'

export const createSocket = (token: string, groupId: string) => {
    const socketio = io(URL, {
        extraHeaders: {
            Authorization: `Bearer ${token}`,
            group_id: groupId || '',
        },
    })

    return socketio
}
