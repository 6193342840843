import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../data/store'
import { DeviceData } from '../data/types'
import { useNetworkManager } from '../network/networkManager'
import DeviceTiles from '../components/devices/deviceTiles'
import { ConfirmationPopup } from '../components/misc/popup'

type ConfirmationAction = 'deleteFile' | 'deleteDevice' | null

type ConfirmationData = {
    deviceId: string
    fileId?: string
}

function Devices() {
    const [devices, setDevices] = useState<DeviceData[]>([])
    const deviceData = useSelector((state: RootState) => state.devices.data)

    const { devices: devicesApi } = useNetworkManager()

    // Unified state for confirmation dialog
    const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(false)
    const [confirmationAction, setConfirmationAction] = useState<ConfirmationAction>(null)
    const [confirmationData, setConfirmationData] = useState<ConfirmationData | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setDevices(deviceData)
    }, [deviceData])

    const handleEditDevice = async (id: string, name: string, description: string) => {
        await devicesApi.updateDevice(id, name, description)
    }

    const handleDeleteDeviceFile = (deviceId: string, fileId: string) => {
        setConfirmationAction('deleteFile')
        setConfirmationData({ deviceId, fileId })
        setIsConfirmationVisible(true)
    }

    const handleDeviceFileDownload = async (deviceId: string, fileId: string) => {
        await devicesApi.downloadDeviceFile(deviceId, fileId)
    }

    const handleDeleteDevice = (deviceId: string) => {
        setConfirmationAction('deleteDevice')
        setConfirmationData({ deviceId })
        setIsConfirmationVisible(true)
    }

    const handleConfirmation = async (confirmation: boolean) => {
        if (confirmation && confirmationData) {
            setLoading(true)
            if (confirmationAction === 'deleteFile' && confirmationData.fileId) {
                await devicesApi.deleteDeviceFile(
                    confirmationData.deviceId,
                    confirmationData.fileId
                )
            } else if (confirmationAction === 'deleteDevice') {
                await devicesApi.deleteDevice(confirmationData.deviceId)
            }
            setLoading(false)
        }
        // Reset state after action
        setIsConfirmationVisible(false)
        setConfirmationAction(null)
        setConfirmationData(null)
    }

    return (
        <div className="machines-container-wrapper">
            <div className="machines-container">
                {isConfirmationVisible && (
                    <ConfirmationPopup
                        ChildComponent={
                            <div>
                                {confirmationAction === 'deleteFile'
                                    ? 'Are you sure you want to delete this file?'
                                    : 'Are you sure you want to delete this device?'}
                            </div>
                        }
                        callback={handleConfirmation}
                        loading={loading}
                    />
                )}
                <DeviceTiles
                    devices={devices}
                    onEditDevice={handleEditDevice}
                    onDeleteDeviceFile={handleDeleteDeviceFile}
                    onDeviceFileDownload={handleDeviceFileDownload}
                    onDeleteDevice={handleDeleteDevice}
                />
            </div>
        </div>
    )
}

export default Devices
