import { configureStore } from '@reduxjs/toolkit'
import machinesReducer from './reducers/machines'
import userReducer from './reducers/user'
import groupsReducer from './reducers/groups'
import sessionsReducer from './reducers/sessions'
import _implementsReducer from './reducers/implements'
import devicesReducer from './reducers/devices'

const store = configureStore({
    reducer: {
        machines: machinesReducer,
        user: userReducer,
        groups: groupsReducer,
        sessions: sessionsReducer,
        _implements: _implementsReducer,
        devices: devicesReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
