import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../data/store'
import { MachineData, SoftwareVersions } from '../data/types'
import VehicleForm from '../components/vehicles/vehiclesForm'
import VehicleTiles from '../components/vehicles/vehiclesTiles'
import { useNetworkManager } from '../network/networkManager'
import { ConfirmationPopup } from '../components/misc/popup'

function Vehicles() {
    const [vehicles, setVehicles] = useState<MachineData[]>([])
    const machineData = useSelector((state: RootState) => state.machines.data)
    const { machines: vehicleApi } = useNetworkManager()

    const [isDialogueVisible, setIsDialogueVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [vehicleToDelete, setVehicleIdToDelete] = useState<string | null>(null)

    useEffect(() => {
        setVehicles(machineData)
    }, [machineData])

    const handleCreateVehicle = (name: string, description: string) => {
        vehicleApi.addMachine(name, description)
    }

    const handleDeleteVehicle = async (machine_id: string) => {
        setVehicleIdToDelete(machine_id)
        setIsDialogueVisible(true)
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && vehicleToDelete) {
            setLoading(true)
            await vehicleApi.deleteMachine(vehicleToDelete)
            setLoading(false)
            setVehicleIdToDelete(null)
        }
        setIsDialogueVisible(false)
    }

    const handleEditVehicle = async (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => {
        vehicleApi.editMachine(id, name, description, softwareVersions)
    }

    return (
        <div className="machines-container-wrapper">
            <div className="machines-container">
                {isDialogueVisible && (
                    <ConfirmationPopup
                        ChildComponent={<div>Are you sure you want to delete this vehicle?</div>}
                        callback={deleteHandler}
                        loading={loading}
                    />
                )}
                <VehicleForm onCreateMachine={handleCreateVehicle} />
                <VehicleTiles
                    machines={vehicles}
                    onEditMachine={handleEditVehicle}
                    onDeleteMachine={handleDeleteVehicle}
                />
            </div>
        </div>
    )
}

export default Vehicles
