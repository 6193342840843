import { useState } from 'react'
import { DeviceData } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import DeviceTile from './deviceTile' // Import the new DeviceTile component
import './devices.css'

interface DeviceTilesProps {
    devices: DeviceData[]
    onEditDevice: (id: string, name: string, description: string) => Promise<void>
    onDeleteDeviceFile: (deviceId: string, fileId: string) => void
    onDeviceFileDownload: (deviceId: string, fileId: string) => Promise<void>
    onDeleteDevice: (deviceId: string) => void
}

function DeviceTiles({
    devices,
    onEditDevice,
    onDeleteDeviceFile,
    onDeviceFileDownload,
    onDeleteDevice,
}: DeviceTilesProps) {
    const [editingDevice, setEditingDevice] = useState<string | null>(null)

    // Separate the devices into online and offline groups
    const onlineDevices = devices.filter((device) => device.isConnected)
    const offlineDevices = devices.filter((device) => !device.isConnected)

    const startEditing = (device: DeviceData) => {
        setEditingDevice(device.id)
    }

    const cancelEditingDevice = () => {
        setEditingDevice(null)
    }

    const saveDevice = async (id: string, name: string, description: string) => {
        await onEditDevice(id, name, description)
        setEditingDevice(null)
    }

    const renderDeviceTile = (device: DeviceData) => (
        <DeviceTile
            key={device.id}
            device={device}
            isEditing={editingDevice === device.id}
            onStartEditing={startEditing}
            onCancelEditing={cancelEditingDevice}
            onSaveDevice={saveDevice}
            onDeleteDeviceFile={(fileId) => onDeleteDeviceFile(device.id, fileId)}
            onDeviceFileDownload={(fileId) => onDeviceFileDownload(device.id, fileId)}
            onDeleteDevice={() => onDeleteDevice(device.id)}
        />
    )

    return (
        <>
            {/* Online Devices */}
            <div className="device-list">
                <div className="status">
                    <div className="title">Devices - Online</div>
                    <div className="indicator online">
                        <FontAwesomeIcon icon={faCircle} />
                    </div>
                </div>
                {onlineDevices.length > 0 && <>{onlineDevices.map(renderDeviceTile)}</>}
            </div>

            {/* Offline Devices */}
            {offlineDevices.length > 0 && (
                <>
                    <div className="device-list">
                        <div className="status">
                            <div className="title">Devices - Offline</div>
                            <div className="indicator offline">
                                <FontAwesomeIcon icon={faCircle} />
                            </div>
                        </div>
                        {offlineDevices.map(renderDeviceTile)}
                    </div>
                </>
            )}
        </>
    )
}

export default DeviceTiles
