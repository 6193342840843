import React, { createContext, useContext, ReactNode } from 'react'
import { AppDispatch } from './store'
import { useDispatch } from 'react-redux'

const DispatchContext = createContext<AppDispatch | undefined>(undefined)

interface DispatchProviderProps {
    children: ReactNode
}

export const DispatchProvider: React.FC<DispatchProviderProps> = ({ children }) => {
    const dispatch = useDispatch<AppDispatch>()
    return <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
}

export const useAppDispatch = (): AppDispatch => {
    const context = useContext(DispatchContext)
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a DispatchProvider')
    }
    return context
}
