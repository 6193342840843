import { useState } from 'react'
import { ImplementData, SoftwareVersions } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

interface ImplementTilesProps {
    _implements: ImplementData[]
    onDeleteImplement: (_implement_id: string) => Promise<void>
    onEditImplement: (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => Promise<void>
}

function ImplementTiles({ _implements, onDeleteImplement, onEditImplement }: ImplementTilesProps) {
    const [editingImplement, setEditingImplement] = useState<string | null>(null)
    const [_implementName, setImplementName] = useState<string>('')
    const [_implementDescription, setImplementDescription] = useState<string>('')
    const [_implementSoftware, setImplementSoftware] = useState<SoftwareVersions[]>([])

    const startEditing = (_implement: ImplementData) => {
        setEditingImplement(_implement.id)
        setImplementName(_implement.name)
        setImplementDescription(_implement.description || '')
        setImplementSoftware(_implement.softwareVersions)
    }

    const handleEditImplement = async (id: string) => {
        await onEditImplement(id, _implementName, _implementDescription || '', _implementSoftware)
        setEditingImplement(null)
    }

    const cancelEditingImplement = () => {
        setEditingImplement(null)
    }

    const handleSoftwareVersionChange = (index: number, value: string) => {
        setImplementSoftware((prevSoftware) =>
            prevSoftware.map((software, i) =>
                i === index ? { ...software, version: value } : software
            )
        )
    }

    return (
        <>
            <header>Existing Implements</header>
            <div className="session-tile">
                {_implements.map((_implement) => (
                    <div key={_implement.id} className="job-tile">
                        <div className="tile-header">
                            <div className="title">
                                {editingImplement === _implement.id ? (
                                    <input
                                        type="text"
                                        value={_implementName}
                                        onChange={(e) => setImplementName(e.target.value)}
                                    />
                                ) : (
                                    _implement.name
                                )}
                            </div>
                            <div className="controls">
                                {editingImplement === _implement.id ? (
                                    <>
                                        <button
                                            className="edit-button"
                                            title="Save Implement"
                                            onClick={() => handleEditImplement(_implement.id)}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className="edit-button"
                                            title="Save Implement"
                                            onClick={() => cancelEditingImplement()}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className="edit-button"
                                            title="Edit Implement"
                                            onClick={() => startEditing(_implement)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button
                                            className="delete-button"
                                            title="Delete Implement"
                                            onClick={() => onDeleteImplement(_implement.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="body">
                            <div className="description">
                                {editingImplement === _implement.id ? (
                                    <div className="software-version-editor">
                                        <span style={{ fontWeight: '600' }}>Software Versions</span>
                                        <div className="input-group">
                                            {_implementSoftware.map((version, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div>{version.name}: </div>
                                                        <input
                                                            value={version.version}
                                                            onChange={(e) =>
                                                                handleSoftwareVersionChange(
                                                                    index,
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <span style={{ fontWeight: '600' }}>Software Versions</span>
                                        <div>
                                            {_implement.softwareVersions.map((version, index) => {
                                                return (
                                                    <div key={index}>
                                                        {version.name}: {version.version}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}

                                <div>
                                    <div style={{ fontWeight: '600' }}>Description</div>
                                    {editingImplement === _implement.id ? (
                                        <div className="text-area-wrapper">
                                            <textarea
                                                value={_implementDescription}
                                                onChange={(e) =>
                                                    setImplementDescription(e.target.value)
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <span
                                                style={{
                                                    fontWeight: '300',
                                                    whiteSpace: 'pre-wrap',
                                                }}
                                            >
                                                {_implement.description}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default ImplementTiles
