import { useState, useEffect, useRef } from 'react'
import { GenericFile } from '../../data/types'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTrashAlt, faExpand } from '@fortawesome/free-solid-svg-icons'
import './imageRenderer.css' // Import the new CSS file

interface RenderImagesProps {
    images: GenericFile[] | undefined
    deleteImage: (imageId: string) => void
    imageUploading: boolean
}

export const RenderImages = ({ images, deleteImage, imageUploading }: RenderImagesProps) => {
    const { getAccessTokenSilently } = useAuth0()
    const [expandedImage, setExpandedImage] = useState<number | null>(null)
    const [imageUrls, setImageUrls] = useState<{ [key: number]: string }>({})
    const [loadingImages, setLoadingImages] = useState<{ [key: number]: boolean }>({})
    const [hoveredImage, setHoveredImage] = useState<number | null>(null)
    const [imageLoading, setImageLoading] = useState<boolean>(false)

    const imageCache = useRef(new Map<string, string>())

    useEffect(() => {
        if (imageUploading) setImageLoading(imageUploading)
    }, [imageUploading])

    useEffect(() => {
        const fetchImagesWithAuth = async () => {
            const token = await getAccessTokenSilently()
            setImageLoading(false)
            if (!images || images.length === 0) return

            const fetchImage = async (image: GenericFile, index: number) => {
                const cachedUrl =
                    imageCache.current.get(image.id) || sessionStorage.getItem(image.id)

                if (cachedUrl) {
                    setImageUrls((prevUrls) => ({
                        ...prevUrls,
                        [index]: cachedUrl,
                    }))

                    const img = new Image()
                    img.src = cachedUrl
                    img.onload = () => {}
                    img.onerror = async () => {
                        imageCache.current.delete(image.id)
                        sessionStorage.removeItem(image.id)
                        await loadImageFromServer(image, index, token)
                    }
                    return
                }
                await loadImageFromServer(image, index, token)
            }

            const loadImageFromServer = async (
                image: GenericFile,
                index: number,
                token: string
            ) => {
                setLoadingImages((prevLoading) => ({
                    ...prevLoading,
                    [index]: true,
                }))
                try {
                    const response = await fetch(image.url || '', {
                        method: 'GET',
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    if (response.ok) {
                        const blob = await response.blob()
                        const blobUrl = URL.createObjectURL(blob)
                        imageCache.current.set(image.id, blobUrl)
                        sessionStorage.setItem(image.id, blobUrl)

                        setImageUrls((prevUrls) => ({
                            ...prevUrls,
                            [index]: blobUrl,
                        }))
                    } else {
                        console.error(`Failed to load image at index ${index}`)
                    }
                } catch (error) {
                    console.error(`Error fetching image at index ${index}:`, error)
                } finally {
                    setLoadingImages((prevLoading) => ({
                        ...prevLoading,
                        [index]: false,
                    }))
                }
            }

            await Promise.all(images.map((image, index) => fetchImage(image, index)))
        }
        fetchImagesWithAuth()
    }, [images, getAccessTokenSilently])

    const handleImageClick = (index: number) => {
        setExpandedImage(expandedImage === index ? null : index)
    }

    return (
        <div className="render-images">
            {images?.map((image, index) => (
                <div
                    key={index}
                    className="image-container"
                    onMouseEnter={() => setHoveredImage(index)}
                    onMouseLeave={() => setHoveredImage(null)}
                >
                    {loadingImages[index] ? (
                        <div className="loading-icon">
                            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                        </div>
                    ) : (
                        <>
                            <div className="image">
                                {expandedImage === index && (
                                    <div
                                        className="fullscreen-overlay"
                                        onClick={() => setExpandedImage(null)}
                                    >
                                        <img
                                            src={imageUrls[index]}
                                            alt={image.friendlyName}
                                            className="fullscreen-image"
                                        />
                                    </div>
                                )}

                                <img
                                    src={imageUrls[index]}
                                    alt={image.friendlyName}
                                    className="thumbnail-image"
                                    onClick={() => handleImageClick(index)}
                                />
                                {hoveredImage === index && (
                                    <div className="icon-controls">
                                        <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            title="Delete Image"
                                            size="lg"
                                            className="delete-icon"
                                            onClick={() => deleteImage(image.id)}
                                        />
                                        <FontAwesomeIcon
                                            icon={faExpand}
                                            title="Expand Image"
                                            size="lg"
                                            className="expand-icon"
                                            onClick={() => handleImageClick(index)}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            ))}

            <div className="loading-icon">
                {imageLoading && <FontAwesomeIcon icon={faSpinner} spin size="3x" />}
            </div>
        </div>
    )
}
