import { RootState } from '../data/store'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import ReportManager from '../components/reports/reportManager'

function Reports() {
    const sessionsData = useSelector((state: RootState) => state.sessions.data)
    const userData = useSelector((state: RootState) => state.user.data)

    const [sessions, setSessions] = useState(sessionsData)

    useEffect(() => {
        setSessions(sessionsData)
    }, [sessionsData])

    const [user, setUser] = useState(userData)

    useEffect(() => {
        setUser(userData)
    }, [userData])

    return <ReportManager sessions={sessions} user={user[0]} />
}

export default Reports
