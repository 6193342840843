import React, { useState } from 'react'
import { SessionData } from '../../data/types'
import { useNetworkManager } from '../../network/networkManager'
import { ConfirmationPopup } from '../misc/popup'
import SessionTile from './sessionTile'
import './sessionTiles.css'
import { Slider } from '../misc/slider'

interface SessionTilesProps {
    sessions: SessionData[] | []
    scrollToEvent: string
}

const SessionTiles: React.FC<SessionTilesProps> = ({ sessions, scrollToEvent = '' }) => {
    const { sessions: sessionsAPI } = useNetworkManager()
    const [isDialogueVisible, setIsDialogueVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [sessionIdToDelete, setSessionIdToDelete] = useState<string | null>(null)
    const [isLocalTime, setIsLocalTime] = useState<boolean>(false)
    const [isDescendingEventOrder, setisDescendingEventOrder] = useState<boolean>(false)

    const deleteSession = async (sessionId: string) => {
        setSessionIdToDelete(sessionId)
        setIsDialogueVisible(true)
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && sessionIdToDelete) {
            setLoading(true)
            await sessionsAPI.deleteSession(sessionIdToDelete, true)
            setLoading(false)
            setSessionIdToDelete(null)
        }
        setIsDialogueVisible(false)
    }

    return (
        <div className="session-tile-wrapper">
            {isDialogueVisible && (
                <ConfirmationPopup
                    ChildComponent={<div>Are you sure you want to delete this session?</div>}
                    callback={deleteHandler}
                    loading={loading}
                />
            )}
            <div className="session-list">
                <div className="toolbar">
                    <div className="tool">
                        <div className="label">Descending Event Order</div>
                        <Slider
                            enabled={isDescendingEventOrder}
                            setEnabled={setisDescendingEventOrder}
                        />
                    </div>
                    <div className="tool">
                        <div className="label">Local time</div>
                        <Slider enabled={isLocalTime} setEnabled={setIsLocalTime} />
                    </div>
                </div>
                {sessions.length > 0 ? (
                    sessions.map((session) => (
                        <SessionTile
                            key={session.id}
                            session={session}
                            onDelete={deleteSession}
                            isUtc={!isLocalTime}
                            isDescending={isDescendingEventOrder}
                            scrollToEvent={scrollToEvent}
                        />
                    ))
                ) : (
                    <div>No Recorded Sessions</div>
                )}
            </div>
        </div>
    )
}

export default SessionTiles
