import { useState, useRef, useLayoutEffect } from 'react'
import { DeviceData } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit,
    faTerminal,
    faTrash,
    faDownload,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { useNetworkManager } from '../../network/networkManager'

interface DeviceTileProps {
    device: DeviceData
    isEditing: boolean
    onStartEditing: (device: DeviceData) => void
    onCancelEditing: () => void
    onSaveDevice: (id: string, name: string, description: string) => Promise<void>
    onDeleteDeviceFile: (fileId: string) => void
    onDeviceFileDownload: (fileId: string) => Promise<void>
    onDeleteDevice: () => void
}

function DeviceTile({
    device,
    isEditing,
    onStartEditing,
    onCancelEditing,
    onSaveDevice,
    onDeleteDeviceFile,
    onDeviceFileDownload,
    onDeleteDevice,
}: DeviceTileProps) {
    const { devices: devicesApi } = useNetworkManager()
    const [deviceName, setDeviceName] = useState<string>(device.name || '')
    const [deviceDescription, setDeviceDescription] = useState<string>(device.description || '')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [dropdownWidth, setDropdownWidth] = useState(0)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const commandHelper = (capability: string) => {
        const payload = { message: 'Test payload' }
        setIsDropdownOpen(false)
        devicesApi.commandDevice(device.id, capability, payload)
    }

    const handleSave = async () => {
        await onSaveDevice(device.id, deviceName, deviceDescription || '')
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    useLayoutEffect(() => {
        if (isDropdownOpen && dropdownRef.current) {
            setDropdownWidth(dropdownRef.current.scrollWidth)
        } else {
            setDropdownWidth(0)
        }
    }, [isDropdownOpen])

    return (
        <div key={device.id} className="job-tile device-tile">
            <div className="tile-header file-header">
                <div className="title">
                    {isEditing ? (
                        <input
                            type="text"
                            value={deviceName}
                            onChange={(e) => setDeviceName(e.target.value)}
                        />
                    ) : (
                        device.name || 'Unknown Device name'
                    )}
                </div>
                <div className="controls">
                    {isEditing ? (
                        <>
                            <button
                                className="edit-button"
                                title="Save Device"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                            <button
                                className="edit-button"
                                title="Cancel Edit"
                                onClick={onCancelEditing}
                            >
                                Cancel
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className="edit-button"
                                title="Edit Device"
                                onClick={() => onStartEditing(device)}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                            {device.isConnected && (
                                <div
                                    className={`command-button-wrapper ${
                                        isDropdownOpen ? `active` : ''
                                    }`}
                                >
                                    <div className="parent">
                                        <button
                                            className="edit-button dropdown-button"
                                            onClick={toggleDropdown}
                                            disabled={device.isExecutingCommand}
                                        >
                                            {device.isExecutingCommand ? (
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                            ) : (
                                                <FontAwesomeIcon icon={faTerminal} />
                                            )}
                                        </button>
                                    </div>
                                    <div
                                        className="dropdown-menu"
                                        ref={dropdownRef}
                                        style={{
                                            width: isDropdownOpen ? `${dropdownWidth}px` : '0px',
                                            visibility: isDropdownOpen ? 'visible' : 'hidden',
                                        }}
                                    >
                                        {isDropdownOpen && (
                                            <>
                                                {device.capabilities &&
                                                Object.keys(device.capabilities).length > 0 ? (
                                                    Object.entries(device.capabilities).map(
                                                        ([key, value]) => (
                                                            <button
                                                                key={key}
                                                                className="command-button"
                                                                onClick={() => commandHelper(key)}
                                                            >
                                                                <span
                                                                    style={{ paddingLeft: '10px' }}
                                                                >
                                                                    {value}
                                                                </span>
                                                            </button>
                                                        )
                                                    )
                                                ) : (
                                                    <div style={{ padding: '10px' }}>
                                                        No capabilities available for this device.
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            <button
                                className="delete-button"
                                title="Delete Device"
                                onClick={() => onDeleteDevice()}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className="body">
                <div className="description">
                    <div>
                        <div style={{ fontWeight: '600' }}>Description</div>
                        {isEditing ? (
                            <div className="text-area-wrapper">
                                <textarea
                                    value={deviceDescription}
                                    onChange={(e) => setDeviceDescription(e.target.value)}
                                />
                            </div>
                        ) : (
                            <span style={{ fontWeight: '300', whiteSpace: 'pre-wrap' }}>
                                {device.description || 'No Description Provided'}
                            </span>
                        )}
                    </div>
                    <div>
                        <div style={{ fontWeight: '600' }}>Device ID</div>
                        <span style={{ fontWeight: '300' }}>{device.deviceId}</span>
                    </div>
                    <div>
                        <div style={{ fontWeight: '600' }}>Connected At</div>
                        <span style={{ fontWeight: '300' }}>
                            {device.connectedAt
                                ? new Date(device.connectedAt).toLocaleString()
                                : 'Not Connected'}
                        </span>
                    </div>
                    {!device.isConnected && (
                        <div>
                            <div style={{ fontWeight: '600' }}>Disconnected At</div>
                            <span style={{ fontWeight: '300' }}>
                                {device.disconnectedAt
                                    ? new Date(device.disconnectedAt).toLocaleString()
                                    : 'Still Connected'}
                            </span>
                        </div>
                    )}

                    {/* Section to list device files */}
                    <div>
                        <div className="file-label">Files</div>
                        {device.files && device.files.length > 0 ? (
                            <div className="device-files">
                                {device.files.map((file, index) => (
                                    <div key={index} className="device-file-card">
                                        <div className="device-file-header">
                                            <div>{file.friendlyName}</div>
                                            {!file.S3UploadProgress && (
                                                <div className="file-actions">
                                                    <button
                                                        className="edit-button"
                                                        title="Download File"
                                                        onClick={() =>
                                                            onDeviceFileDownload(file.id)
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </button>
                                                    <button
                                                        className="delete-button"
                                                        title="Delete File"
                                                        onClick={() => onDeleteDeviceFile(file.id)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        <div className="file-value">Size: {file.fileSize}</div>
                                        {file.S3UploadProgress && (
                                            <div className="file-value">
                                                Progress: {file.S3UploadProgress}
                                            </div>
                                        )}
                                        <div className="file-value">
                                            Date Created: {file.dateCreated}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <span className="no-files-message">No Files Available</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceTile
