import { useState, FC, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBug,
    faExclamationTriangle,
    faCloudDownload,
    faSyncAlt,
    faClose,
} from '@fortawesome/free-solid-svg-icons'

import { CrossedOffIcon } from '../utils/misc'
import { useNetworkManager } from '../../network/networkManager'
import GenericEventForm from './events/genericEventForm'
import SoftwareUpdateForm from './events/softwareUpdateForm'
import { useResizeObserver } from '../utils/misc'
import { RecordButtonLeftSpacer, RecorderButtons } from './sessionManager'
import { ImplementData, MachineData } from '../../data/types'

import './bugReporter.css'
import { useMachineUpdater } from '../vehicles/vehicleSoftwareUpdate'

interface SnapshotManagerProps {
    machines: MachineData[]
    _implements: ImplementData[]
    sessionId: string
    isSessionActive: boolean
    isSessionOwner: boolean
    isPreviousSessionSet: boolean
    notifyStopSuccess: () => void
    notifyEventReported: (classification: string) => void
}

const SnapshotManager: FC<SnapshotManagerProps> = ({
    machines,
    _implements,
    sessionId,
    isSessionActive,
    isSessionOwner,
    isPreviousSessionSet,
    notifyStopSuccess,
    notifyEventReported,
}) => {
    const [showBugForm, setShowBugForm] = useState(false)
    const [showEventForm, setShowEventForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)
    const [formBottomOffset, setFormBottomOffset] = useState<number | null>(null)
    const [initiationTimestamp, setInitiationTimestamp] = useState<string>('')

    const { sessions: sessionsAPI } = useNetworkManager()
    const { updateSoftwareVersion } = useMachineUpdater()

    // Update initiation timestamp whenever a form is opened
    const updateInitiationTimestamp = () => {
        setInitiationTimestamp(new Date().toISOString())
    }

    const toggleBugForm = () => {
        setShowBugForm(!showBugForm)
        setShowEventForm(false)
        setShowUpdateForm(false)
        updateInitiationTimestamp()
    }

    const toggleEventForm = () => {
        setShowEventForm(!showEventForm)
        setShowBugForm(false)
        setShowUpdateForm(false)
        updateInitiationTimestamp()
    }

    const toggleUpdateForm = () => {
        setShowUpdateForm(!showUpdateForm)
        setShowBugForm(false)
        setShowEventForm(false)
        updateInitiationTimestamp()
    }

    const bugSubmitted = (classification: string) => {
        setShowBugForm(false)
        setShowEventForm(false)
        setShowUpdateForm(false)
        notifyEventReported(classification)
    }

    const hideForms = () => {
        setShowBugForm(false)
        setShowEventForm(false)
        setShowUpdateForm(false)
    }

    const logData = async (object: Record<string, any>, classification: string) => {
        if (sessionId) {
            try {
                if (Object.keys(object).length === 0) {
                    const placeholder = {
                        [classification]: {
                            placeholder: '<no input provided>',
                        },
                    }
                    await sessionsAPI.addSnapshot(sessionId, initiationTimestamp, placeholder)
                } else {
                    await sessionsAPI.addSnapshot(sessionId, initiationTimestamp, object)
                }
                bugSubmitted(classification)
            } catch (error) {
                console.error('Error logging data:', error)
            }
        } else {
            alert('No session ID provided')
        }
    }

    const logSoftwareUpdate = async (
        object: Record<string, any>,
        classification: string,
        softwareType: 'machine' | 'implement'
    ) => {
        if (sessionId) {
            try {
                await sessionsAPI.addSnapshot(sessionId, initiationTimestamp, object)
                const name = object['update'].Name
                const version = object['update'].Version
                await updateSoftwareVersion(sessionId, { name, version }, softwareType)
                bugSubmitted(classification)
            } catch (error) {
                console.error('Error logging data:', error)
            }
        } else {
            alert('No session ID provided')
        }
    }

    const handleResize = useCallback(() => {
        if (bugReporterParentRef.current) {
            const parentHeight = bugReporterParentRef.current.clientHeight
            setFormBottomOffset(parentHeight)
        }

        // eslint-disable-next-line
    }, [])

    const bugReporterParentRef = useResizeObserver(handleResize)

    useEffect(() => {
        handleResize() // Initial calculation
    }, [showBugForm, showEventForm, showUpdateForm, handleResize])

    const isVisible = showBugForm || showEventForm || showUpdateForm

    const [isRecordButtonsVisible, seIsRecordButtonVisisble] = useState(!isPreviousSessionSet)

    useEffect(() => {
        seIsRecordButtonVisisble(isPreviousSessionSet)
    }, [isPreviousSessionSet])
    const toggleRecordButtons = () => {
        seIsRecordButtonVisisble(!isRecordButtonsVisible)
    }

    return (
        <div className="recorder-toolbox">
            {!isPreviousSessionSet && (
                <div>
                    <button onClick={toggleRecordButtons}>
                        {isRecordButtonsVisible ? 'Hide Session Form' : 'Show Session Form'}
                    </button>
                </div>
            )}
            {isRecordButtonsVisible && (
                <RecorderButtons
                    machines={machines}
                    _implements={_implements}
                    sessionId={sessionId}
                    notifyStopSuccess={notifyStopSuccess}
                    isSessionActive={isSessionActive}
                    isSessionOwner={isSessionOwner}
                />
            )}
            <div className="recording-icons actions">
                {isSessionActive && (
                    <>
                        <div
                            className={`icon-button bug ${showBugForm ? 'active' : ''}`}
                            onClick={toggleBugForm}
                        >
                            <div className="icon">
                                <FontAwesomeIcon icon={faBug} />
                            </div>
                            {window.innerWidth > 800 && <div>Bug</div>}
                        </div>
                        <div
                            className={`icon-button event ${showEventForm ? 'active' : ''}`}
                            onClick={toggleEventForm}
                        >
                            <div className="icon">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </div>
                            {window.innerWidth > 800 && <div>Event</div>}
                        </div>
                        <div
                            className={`icon-button update ${showUpdateForm ? 'active' : ''}`}
                            onClick={toggleUpdateForm}
                        >
                            <div className="icon">
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </div>
                            {window.innerWidth > 800 && <div>Software Update</div>}
                        </div>
                        <div className={`icon-button disabled`}>
                            <div className="icon">
                                <CrossedOffIcon icon={faCloudDownload} />
                            </div>
                            {window.innerWidth > 800 && <div>Pull Logs</div>}
                        </div>
                    </>
                )}
                <div
                    className={`bug-reporter-parent-wrapper ${!isVisible ? 'hidden' : ''}`}
                    ref={bugReporterParentRef}
                    style={{ bottom: formBottomOffset ? -formBottomOffset - 50 : 'auto' }}
                >
                    <div className="close-icon" onClick={hideForms}>
                        <FontAwesomeIcon icon={faClose} />
                    </div>
                    {showBugForm && (
                        <GenericEventForm addSnapshot={logData} eventClassification="bug" />
                    )}
                    {showEventForm && (
                        <GenericEventForm addSnapshot={logData} eventClassification="event" />
                    )}
                    {showUpdateForm && <SoftwareUpdateForm addSnapshot={logSoftwareUpdate} />}
                </div>
            </div>
            {isSessionActive && <RecordButtonLeftSpacer />}
        </div>
    )
}

export default SnapshotManager
