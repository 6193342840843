import { AppDispatch } from '../../data/store'
import { ApolloClient, ApolloError, NormalizedCacheObject, gql } from '@apollo/client'
import {
    fetchDataRequest,
    fetchDataFailure,
    fetchSessionsSuccess,
    addSessionSuccess,
    editSessionSuccess,
    stopSessionSuccess,
    deleteSessionSuccess,
    addSnapshotSuccess,
    deleteSnapshotSuccess,
    editSnapshotSuccess,
} from '../../data/reducers/sessions'

import { SessionData, SoftwareItems } from '../../data/types'

export const fetchSessions = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const GET_SESSIONS = gql`
        query GetSessions {
            sessions {
                id
                title
                startTime
                stopTime
                active
                owner {
                    id
                    given_name
                    family_name
                }
                machine {
                    id
                    name
                }
                implement {
                    id
                    name
                }
                softwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                finalSoftwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                snapshots {
                    id
                    initiationTimestamp
                    submittedTimestamp
                    description
                    creator {
                        given_name
                        family_name
                    }
                    images {
                        id
                        friendlyName
                        url
                    }
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.query({
            query: GET_SESSIONS,
        })
        const sessions: SessionData[] = response.data.sessions
        dispatch(fetchSessionsSuccess(sessions))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const editSession = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    sessionId: string,
    title: string,
    softwareVersions: SoftwareItems
) => {
    const EDIT_SESSION = gql`
        mutation EditSession(
            $sessionId: ID!
            $title: String
            $softwareVersions: SoftwareItemsInput
        ) {
            editSession(sessionId: $sessionId, title: $title, softwareVersions: $softwareVersions) {
                id
                title
                startTime
                stopTime
                active
                owner {
                    id
                    given_name
                    family_name
                }
                machine {
                    id
                    name
                }
                implement {
                    id
                    name
                }
                softwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                finalSoftwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                snapshots {
                    id
                    initiationTimestamp
                    submittedTimestamp
                    description
                    creator {
                        given_name
                        family_name
                    }
                    images {
                        id
                        friendlyName
                        url
                    }
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: EDIT_SESSION,
            variables: { sessionId, title, softwareVersions },
        })
        dispatch(editSessionSuccess(response.data.editSession))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const addSession = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    machineId: string,
    implementId: string,
    title: string,
    softwareVersions: SoftwareItems
) => {
    const ADD_SESSION = gql`
        mutation AddSession(
            $machineId: ID!
            $implementId: ID!
            $title: String
            $softwareVersions: SoftwareItemsInput
        ) {
            addSession(
                machineId: $machineId
                implementId: $implementId
                title: $title
                softwareVersions: $softwareVersions
            ) {
                id
                title
                startTime
                stopTime
                active
                owner {
                    id
                    given_name
                    family_name
                }
                machine {
                    id
                    name
                }
                implement {
                    id
                    name
                }
                softwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                finalSoftwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                snapshots {
                    id
                    initiationTimestamp
                    submittedTimestamp
                    description
                    creator {
                        given_name
                        family_name
                    }
                    images {
                        id
                        friendlyName
                        url
                    }
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: ADD_SESSION,
            variables: { machineId, implementId, title, softwareVersions },
        })
        dispatch(addSessionSuccess(response.data.addSession))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const stopSession = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string,
    finalSoftwareVersion?: SoftwareItems
) => {
    const STOP_SESSION = gql`
        mutation StopSession($id: ID!, $finalSoftwareVersion: SoftwareItemsInput) {
            stopSession(id: $id, softwareVersions: $finalSoftwareVersion) {
                id
                title
                startTime
                stopTime
                active
                owner {
                    id
                    given_name
                    family_name
                }
                machine {
                    id
                    name
                }
                implement {
                    id
                    name
                }
                softwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                finalSoftwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                snapshots {
                    id
                    initiationTimestamp
                    submittedTimestamp
                    description
                    creator {
                        given_name
                        family_name
                    }
                    images {
                        id
                        friendlyName
                        url
                    }
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: STOP_SESSION,
            variables: { id, finalSoftwareVersion },
        })
        dispatch(stopSessionSuccess(response.data.stopSession))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const editSnapshot = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    snapshotId: string,
    description: any
) => {
    const EDIT_SNAPSHOT = gql`
        mutation EditSnapshot($id: ID!, $description: JSON) {
            editSnapshot(id: $id, description: $description) {
                id
                initiationTimestamp
                submittedTimestamp
                description
                session {
                    id
                    title
                }
                creator {
                    given_name
                    family_name
                }
                images {
                    id
                    friendlyName
                    url
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: EDIT_SNAPSHOT,
            variables: { id: snapshotId, description },
        })
        dispatch(editSnapshotSuccess(response.data.editSnapshot))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const deleteSession = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    id: string,
    deleteSnapshots: boolean
) => {
    const DELETE_SESSION = gql`
        mutation DeleteSession($id: ID!, $deleteSnapshots: Boolean) {
            deleteSession(id: $id, deleteSnapshots: $deleteSnapshots) {
                id
                title
                startTime
                stopTime
                active
                owner {
                    id
                    given_name
                    family_name
                }
                machine {
                    id
                    name
                }
                implement {
                    id
                    name
                }
                softwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                finalSoftwareVersions {
                    machines {
                        name
                        version
                    }
                    _implements {
                        name
                        version
                    }
                }
                snapshots {
                    id
                    initiationTimestamp
                    submittedTimestamp
                    description
                    creator {
                        given_name
                        family_name
                    }
                    images {
                        id
                        friendlyName
                        url
                    }
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: DELETE_SESSION,
            variables: { id, deleteSnapshots },
        })
        dispatch(deleteSessionSuccess(response.data.deleteSession))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const addSnapshot = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    sessionId: string,
    initiationTimestamp: Date,
    description: any
) => {
    const ADD_SNAPSHOT = gql`
        mutation AddSnapshot($sessionId: ID!, $initiationTimestamp: DateTime!, $description: JSON) {
            addSnapshot(
                sessionId: $sessionId
                initiationTimestamp: $initiationTimestamp
                description: $description
            ) {
                id
                initiationTimestamp
                submittedTimestamp
                description
                session {
                    id
                    title
                }
                creator {
                    given_name
                    family_name
                }
                images {
                    id
                    friendlyName
                    url
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: ADD_SNAPSHOT,
            variables: { sessionId, initiationTimestamp, description },
        })
        dispatch(addSnapshotSuccess(response.data.addSnapshot))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const deleteSnapshot = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    snapshotId: string
) => {
    const DELETE_SNAPSHOT = gql`
        mutation DeleteSnapshot($snapshotId: ID!) {
            deleteSnapshot(id: $snapshotId) {
                id
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.mutate({
            mutation: DELETE_SNAPSHOT,
            variables: { snapshotId },
        })
        dispatch(deleteSnapshotSuccess(response.data.deleteSnapshot.id))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}
