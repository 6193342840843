import React, { useState, useCallback, FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../data/store'
import { ReporterProfile } from '../../../data/types'

interface EventFormProps {
    addSnapshot: (
        object: Record<string, any>,
        classification: string,
        softwareType: 'machine' | 'implement'
    ) => Promise<void>
}

const SoftwareUpdateForm: FC<EventFormProps> = ({ addSnapshot }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedArtifact, setSelectedArtifact] = useState<string>('AIC')
    const [selectedSoftwareType, setSelectedSoftwareType] = useState<'machine' | 'implement'>(
        'machine'
    )
    const [nestedInputs, setNestedInputs] = useState<{ [key: string]: string }>({})
    const groupData = useSelector((state: RootState) => state.groups.data)
    const [reporterProfile, setReporterProfile] = useState<ReporterProfile | null>(null)

    useEffect(() => {
        if (groupData && groupData.length > 0) {
            setReporterProfile(groupData[0].reporterProfile || null)
        }
    }, [groupData])

    const submitBug = async () => {
        setLoading(true) // Disable the form and show spinner

        const data = {
            update: {
                Rationale: nestedInputs['Description'] || '<no input provided>',
                Name: selectedArtifact,
                Version: nestedInputs[selectedArtifact] || 'N/A',
            },
        }

        await addSnapshot(data, 'Software Update', selectedSoftwareType)
        setLoading(false)
    }

    const handleInputChange = useCallback((prompt: string, value: string) => {
        setNestedInputs((prevInputs) => ({
            ...prevInputs,
            [prompt]: value,
        }))
    }, [])

    const handleArtifactChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value
        setSelectedArtifact(value)

        if (
            reporterProfile?.loggingConfiguration?.softwareItems?.machines.some(
                (artifact: string) => artifact === value
            )
        ) {
            setSelectedSoftwareType('machine')
        } else if (
            reporterProfile?.loggingConfiguration?.softwareItems?._implements.some(
                (artifact: string) => artifact === value
            )
        ) {
            setSelectedSoftwareType('implement')
        }
    }

    const renderInputField = (option: string) => {
        return (
            <div key={option}>
                <label>{`${option} software version`}</label>
                <input
                    type="text"
                    value={nestedInputs[`${option}`] || ''}
                    onChange={(e) => handleInputChange(`${option}`, e.target.value)}
                    disabled={loading}
                    placeholder="N/A"
                />
            </div>
        )
    }

    return (
        <div className="bug-reporter-parent">
            <div className="bug-reporter">
                <div className="title">Software Update</div>
                <div className="bug-form">
                    <div>
                        <label>Description/Rationale:</label>
                        <input
                            type="text"
                            value={nestedInputs['Description'] || ''}
                            onChange={(e) => handleInputChange('Description', e.target.value)}
                            disabled={loading}
                            placeholder="N/A"
                        />
                    </div>
                    <div>
                        <label>Software Artifact:</label>
                        <select
                            value={selectedArtifact}
                            onChange={handleArtifactChange}
                            disabled={loading}
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {reporterProfile?.loggingConfiguration?.softwareItems?.machines &&
                                reporterProfile?.loggingConfiguration?.softwareItems?.machines
                                    .length > 0 && (
                                    <optgroup label="Machines">
                                        {reporterProfile.loggingConfiguration?.softwareItems.machines.map(
                                            (artifact: string) => (
                                                <option key={artifact} value={artifact}>
                                                    {artifact}
                                                </option>
                                            )
                                        )}
                                    </optgroup>
                                )}
                            {reporterProfile?.loggingConfiguration?.softwareItems?._implements &&
                                reporterProfile?.loggingConfiguration?.softwareItems?._implements
                                    .length > 0 && (
                                    <optgroup label="Implements">
                                        {reporterProfile.loggingConfiguration?.softwareItems._implements.map(
                                            (artifact: string) => (
                                                <option key={artifact} value={artifact}>
                                                    {artifact}
                                                </option>
                                            )
                                        )}
                                    </optgroup>
                                )}
                        </select>
                    </div>
                    {renderInputField(selectedArtifact)}
                </div>

                <button className={'icon-button'} onClick={submitBug} disabled={loading}>
                    {loading ? <span className="spinner"></span> : 'Log Data'}
                </button>
            </div>
        </div>
    )
}

export default SoftwareUpdateForm
