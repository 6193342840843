import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './data/store'

// Components
import { DispatchProvider } from './data/dispatchContext'
import { AuthProvider, ProtectedRoute } from './routes/ProtectedRoutes'
import { MainWrapper } from './routes/MainWrapper'
import Recorder from './pages/recorder'
import Vehicles from './pages/vehicles'
import Implements from './pages/implements'
import RecordedSessions from './pages/recordedSessions'
import Devices from './pages/devices'
import Reports from './pages/reports'
import UserManagement from './pages/userManagement'

// Styles
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
    <Provider store={store}>
        <DispatchProvider>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route element={<ProtectedRoute />}>
                            <Route element={<MainWrapper />}>
                                <Route path="/" element={<Recorder />} />
                                <Route path="vehicles" element={<Vehicles />} />
                                <Route path="implements" element={<Implements />} />
                                <Route path="recorded-sessions" element={<RecordedSessions />} />
                                <Route path="devices" element={<Devices />} />
                                <Route path="reports" element={<Reports />} />

                                {/* disable route when not admin */}
                                <Route path="user-management" element={<UserManagement />} />
                            </Route>
                        </Route>
                    </Routes>
                </Router>
            </AuthProvider>
        </DispatchProvider>
    </Provider>
)
