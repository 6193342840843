import Recorder from '../components/recorder/recorder'
import { useSelector } from 'react-redux'
import { RootState } from '../data/store'

function App() {
    const machines = useSelector((state: RootState) => state.machines.data)
    const _implements = useSelector((state: RootState) => state._implements.data)

    return <Recorder machines={machines} _implements={_implements} />
}

export default App
