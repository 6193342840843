import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, MachineData, ErrorPayload } from '../types'
import { sanitizeMachinesData, sanitizeMachineData } from '../../components/utils/dataSanitizer'

const initialState: DataState<MachineData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

const dataSlice = createSlice({
    name: 'machines',
    initialState,
    reducers: {
        // Start & Error handlers
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },

        // Result handlers
        fetchMachinesSuccess(state, action: PayloadAction<MachineData[]>) {
            state.loading = false
            state.data = sanitizeMachinesData(action.payload)
            state.error.message = ''
        },
        addMachineSuccess(state, action: PayloadAction<MachineData>) {
            state.loading = false
            const machineIndex = state.data.findIndex((machine) => machine.id === action.payload.id)
            if (machineIndex === -1) {
                state.data.push(action.payload) // Add new machine
            } else {
                state.data[machineIndex] = action.payload // Update existing machine
            }
            state.error.message = ''
        },
        editMachineSuccess(state, action: PayloadAction<MachineData>) {
            state.loading = false
            const machineIndex = state.data.findIndex((machine) => machine.id === action.payload.id)
            if (machineIndex !== -1) {
                state.data[machineIndex] = sanitizeMachineData(action.payload)
            }
            state.error.message = ''
        },
        deleteMachineSuccess(state, action: PayloadAction<MachineData>) {
            state.loading = false
            state.data = state.data.filter((machine) => machine.id !== action.payload.id)
            state.error.message = ''
        },
    },
})

export const {
    fetchDataRequest,
    fetchDataFailure,
    fetchMachinesSuccess,
    addMachineSuccess,
    editMachineSuccess,
    deleteMachineSuccess,
} = dataSlice.actions
export default dataSlice.reducer
