import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

// Determine base URL depending on the environment
const baseURL =
    window.location.hostname === 'localhost'
        ? 'http://localhost:8000'
        : 'https://fieldeventreporter.com:8000'

// Helper function to create Axios instance with Authorization and custom headers
const createAuthenticatedRequest = async (getAccessTokenSilently: () => Promise<string>) => {
    const token = await getAccessTokenSilently()
    const groupId = localStorage.getItem('groupId') || '' // Retrieve groupId from local storage

    return axios.create({
        baseURL,
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
            group_id: groupId, // Add groupId to headers
        },
    })
}

export const useFileManager = () => {
    const { getAccessTokenSilently } = useAuth0()

    return {
        imageUpload: {
            uploadImage: async (sessionId: string, snapshotId: string, file: File) => {
                try {
                    const client = await createAuthenticatedRequest(getAccessTokenSilently)
                    client.defaults.maxContentLength = Infinity
                    client.defaults.maxBodyLength = Infinity

                    // Custom headers, without 'Content-Type'
                    const headers = {
                        'file-name': file.name,
                        Accept: 'application/json', // Optional, to ensure JSON response format
                    }

                    // POST request streaming the file directly
                    const response = await client.post(
                        `/uploads/images/${sessionId}/${snapshotId}`,
                        file,
                        { headers }
                    )

                    return response.data
                } catch (error) {
                    console.error('Error uploading image:', error)
                    throw error
                }
            },

            deleteImage: async (sessionId: string, snapshotId: string, fileId: string) => {
                try {
                    const client = await createAuthenticatedRequest(getAccessTokenSilently)

                    // Make DELETE request to delete image
                    const response = await client.delete(
                        `/uploads/images/${sessionId}/${snapshotId}/${fileId}`
                    )

                    return response.data // Return response data to handle it in the calling function
                } catch (error) {
                    console.error('Error deleting image:', error)
                    throw error // Throw error to be caught by the calling function
                }
            },
        },
    }
}
