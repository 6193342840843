import { AppDispatch } from '../../data/store'
import { ApolloClient, ApolloError, NormalizedCacheObject, gql } from '@apollo/client'
import { fetchDataRequest, fetchDataFailure, fetchGroupsSuccess } from '../../data/reducers/groups'

export const getGroupProfile = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const GET_GROUP_PROFILE = gql`
        query GetGroupProfile {
            getGroupProfile {
                id
                name
                reporterProfile {
                    id
                    name
                    loggingConfiguration
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.query({
            query: GET_GROUP_PROFILE,
        })

        dispatch(fetchGroupsSuccess([response.data.getGroupProfile]))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const fetchGroups = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const GET_GROUPS = gql`
        query GetGroups {
            groups {
                id
                name
                users {
                    id
                    given_name
                    family_name
                }
                reporterProfile {
                    id
                    name
                    loggingConfiguration
                }
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.query({
            query: GET_GROUPS,
        })

        dispatch(fetchGroupsSuccess(response.data.groups))
    } catch (error) {
        const message = error instanceof ApolloError ? error.message : 'Unknown error occurred'
        dispatch(fetchDataFailure({ message }))
    }
}

export const addUser = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    userId: String,
    groupId: String
) => {
    const ADD_USER = gql`
        mutation AddUser($userId: ID!, $groupId: ID!) {
            addUser(userId: $userId, groupId: $groupId) {
                id
                name
                users {
                    id
                    given_name
                    family_name
                }
            }
        }
    `

    try {
        const response = await client.mutate({
            mutation: ADD_USER,
            variables: { userId, groupId },
        })

        return response.data.addUser
    } catch (error) {
        console.error(error)
    }
}

export const removeUser = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    userId: string,
    groupId: string
) => {
    const REMOVE_USER = gql`
        mutation DeleteUser($userId: ID!, $groupId: ID!) {
            deleteUser(userId: $userId, groupId: $groupId) {
                id
                name
                users {
                    id
                    given_name
                    family_name
                }
            }
        }
    `

    try {
        const response = await client.mutate({
            mutation: REMOVE_USER,
            variables: { userId, groupId },
        })

        return response.data.removeUser
    } catch (error) {
        console.error(error)
    }
}

export const addGroup = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch,
    groupName: string
) => {
    const CREATE_GROUP = gql`
        mutation CreateGroup($groupName: String!) {
            createGroup(groupName: $groupName) {
                id
                name
                users {
                    id
                    given_name
                    family_name
                }
            }
        }
    `

    try {
        const response = await client.mutate({
            mutation: CREATE_GROUP,
            variables: { groupName },
        })

        return response.data.createGroup
    } catch (error) {
        console.error(error)
    }
}
