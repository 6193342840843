import { useState, ChangeEvent } from 'react'

interface JobFormProps {
    onCreateMachine: (name: string, description: string) => void
}

function MachineForm({ onCreateMachine }: JobFormProps) {
    const [machineName, setMachineName] = useState<string>('')
    const [machineDescription, setMachineDescription] = useState<string>('')

    const handleMachineNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMachineName(e.target.value)
    }

    const handleMachineDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setMachineDescription(e.target.value)
    }

    const handleCreateMachine = () => {
        onCreateMachine(machineName, machineDescription)
        setMachineName('')
        setMachineDescription('')
    }

    return (
        <div className="jobs-form">
            <header>Create New Vehicle</header>
            <div>
                <div>
                    <label>Vehicle Name:</label>
                    <input type="text" value={machineName} onChange={handleMachineNameChange} />
                </div>
                <div>
                    <label>Vehicle Description:</label>
                    <textarea
                        value={machineDescription}
                        onChange={handleMachineDescriptionChange}
                    />
                </div>
                <div>
                    <button onClick={handleCreateMachine}>Create Vehicle</button>
                </div>
            </div>
        </div>
    )
}

export default MachineForm
