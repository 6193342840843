import React, { useState, useCallback, FC, useEffect } from 'react'
import { capitalizeFirstLetter } from '../../utils/misc'
import { useSelector } from 'react-redux'
import { ReporterProfile } from '../../../data/types'
import { RootState } from '../../../data/store'

interface GenericEventFormProps {
    addSnapshot: (object: Record<string, any>, classification: string) => Promise<void>
    eventClassification: 'bug' | 'event'
    closeMenu?: () => void
    initialData?: Record<string, any>
}

// todo: complexity/fragility of this suggest a difficult to work with datastructure
const transformInitialData = (data: Record<string, any> = {}, availableSubMenus: string[]) => {
    let menu = ''
    let subMenu = ''
    const inputs: Record<string, string> = {}

    if (data) {
        const description = data['event'] ? data['event'] : data['bug']
        if (description) {
            if (!description.placeholder) {
                const typeKeys = Object.keys(description)
                if (typeKeys.length > 0) {
                    menu = typeKeys[0]
                    const subMenuKeys = Object.keys(description[menu])
                    if (subMenuKeys.length > 0) {
                        if (availableSubMenus.includes(subMenuKeys[0])) {
                            subMenu = subMenuKeys[0]
                            const nestedInputs = description[menu][subMenu]
                            Object.keys(nestedInputs).forEach((key) => {
                                if (key !== 'placeholder') inputs[key] = nestedInputs[key]
                            })
                        } else {
                            const nestedInputs = description[menu]
                            Object.keys(nestedInputs).forEach((key) => {
                                if (key !== 'placeholder') inputs[key] = nestedInputs[key]
                            })
                        }
                    }
                }
            }
        }
    }

    return { menu, subMenu, inputs }
}

const GenericEventForm: FC<GenericEventFormProps> = ({
    addSnapshot,
    eventClassification,
    closeMenu,
    initialData,
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedMenu, setSelectedMenu] = useState<string>('')
    const [selectedSubMenu, setSelectedSubMenu] = useState<string>('')
    const [nestedInputs, setNestedInputs] = useState<{ [key: string]: string }>({})

    const groupData = useSelector((state: RootState) => state.groups.data)
    const [reporterProfile, setReporterProfile] = useState<ReporterProfile | null>(null)

    useEffect(() => {
        if (reporterProfile) {
            const transformedInitialData = transformInitialData(initialData || {}, [
                ...reporterProfile?.loggingConfiguration?.softwareItems?.machines,
                ...reporterProfile?.loggingConfiguration?.softwareItems?._implements,
                ...reporterProfile?.loggingConfiguration?.productionSystems,
            ])

            setSelectedMenu(transformedInitialData.menu)
            setSelectedSubMenu(transformedInitialData.subMenu)
            setNestedInputs(transformedInitialData.inputs)
        }
    }, [reporterProfile, initialData])

    useEffect(() => {
        if (groupData && groupData.length > 0) {
            setReporterProfile(groupData[0].reporterProfile || null)
        }
    }, [groupData])

    const submitBug = async (type: string) => {
        setLoading(true) // Disable the form and show spinner
        let data: Record<string, any> = {}

        // Check if nestedInputs has any values
        const hasValues = Object.values(nestedInputs).some(
            (value) => value !== null && value !== undefined && value !== ''
        )

        if (type && selectedMenu && selectedSubMenu) {
            data = {
                [type]: {
                    [selectedMenu]: {
                        [selectedSubMenu]: {
                            ...nestedInputs,
                        },
                    },
                },
            }

            if (!hasValues)
                data[type][selectedMenu][selectedSubMenu] = {
                    placeholder: '<no input provided>',
                }
        } else if (type && selectedMenu) {
            data = {
                [type]: {
                    [selectedMenu]: {
                        ...nestedInputs,
                    },
                },
            }
            if (!hasValues) data[type][selectedMenu] = { placeholder: '<no input provided>' }
        }

        await addSnapshot(data, eventClassification)
        setLoading(false)
    }

    const handleMenuChange = (menu: string) => {
        setSelectedMenu(menu)

        if (!initialData) {
            // todo: this needs to validate if the current selectioin is available
            setSelectedSubMenu('')
            setNestedInputs({})
        }
    }

    const handleSubMenuChange = (subMenu: string) => {
        setSelectedSubMenu(subMenu)
    }

    const handleInputChange = useCallback((prompt: string, value: string) => {
        setNestedInputs((prevInputs) => ({
            ...prevInputs,
            [prompt]: value,
        }))
    }, [])

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNestedInputs((prevInputs) => ({
            ...prevInputs,
            'Additional Comments': e.target.value,
        }))
    }

    const renderSubMenuOptions = () => {
        if (selectedMenu === 'Raven Stack') {
            return (
                <>
                    {(reporterProfile?.loggingConfiguration?.softwareItems?.machines || []).map(
                        (item: string, index: number) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        )
                    )}
                    {(reporterProfile?.loggingConfiguration?.softwareItems?._implements || []).map(
                        (item: string, index: number) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        )
                    )}
                </>
            )
        }
        return (reporterProfile?.loggingConfiguration?.productionSystems || []).map(
            (item: string, index: number) => (
                <option key={index} value={item}>
                    {item}
                </option>
            )
        )
    }

    return (
        <div className="bug-reporter-parent">
            <div className="bug-reporter">
                <div className="title">{capitalizeFirstLetter(eventClassification)}</div>
                <select
                    value={selectedMenu}
                    onChange={(e) => handleMenuChange(e.target.value)}
                    disabled={loading}
                >
                    <option value="" disabled>
                        Select a category
                    </option>
                    {(reporterProfile?.loggingConfiguration?.genericSystemClassification || []).map(
                        (menu: string) => (
                            <option key={menu} value={menu}>
                                {menu}
                            </option>
                        )
                    )}
                </select>

                {selectedMenu && (
                    <div className="submenu">
                        <div className="submenu-title">
                            {selectedMenu} {`>`}
                        </div>
                        <select
                            value={selectedSubMenu}
                            onChange={(e) => handleSubMenuChange(e.target.value)}
                            disabled={loading}
                        >
                            <option value="" disabled>
                                Select an option
                            </option>
                            {renderSubMenuOptions()}
                        </select>
                    </div>
                )}

                {selectedMenu && (
                    <div className="bug-form">
                        <div>
                            <label>Description:</label>
                            <input
                                type="text"
                                value={nestedInputs['Description'] || ''}
                                onChange={(e) => handleInputChange('Description', e.target.value)}
                                disabled={loading}
                                placeholder="N/A"
                            />
                        </div>
                        <div>
                            <label>Steps to repeat:</label>
                            <input
                                type="text"
                                value={nestedInputs['Steps to repeat'] || ''}
                                onChange={(e) =>
                                    handleInputChange('Steps to repeat', e.target.value)
                                }
                                disabled={loading}
                                placeholder="N/A"
                            />
                        </div>
                        <div>
                            <label>Additional Comments</label>
                            <textarea
                                value={nestedInputs['Additional Comments'] || ''}
                                onChange={handleCommentChange}
                                disabled={loading}
                                placeholder="N/A"
                            />
                        </div>
                    </div>
                )}

                <div className="controls">
                    <button
                        className={'icon-button'}
                        onClick={() => submitBug(eventClassification)}
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="spinner"></span>
                        ) : initialData ? (
                            'Update'
                        ) : (
                            'Log Data'
                        )}
                    </button>
                    {closeMenu && (
                        <button
                            className={'icon-button'}
                            onClick={() => closeMenu()}
                            disabled={loading}
                        >
                            {'Cancel'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default GenericEventForm
