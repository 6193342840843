import { AppDispatch } from '../../data/store'
import { fetchDataRequest, fetchDataFailure, fetchUserSuccess } from '../../data/reducers/user'
import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client'

export const fetchUser = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const GET_USER = gql`
        query GetUser {
            getUser {
                id
                given_name
                family_name
                groups {
                    id
                    name
                }
                roles
            }
        }
    `

    dispatch(fetchDataRequest())
    try {
        const response = await client.query({
            query: GET_USER,
        })
        dispatch(fetchUserSuccess(response.data.getUser))
    } catch (error) {
        dispatch(fetchDataFailure(error as Error))
    }
}

// Note: Only used by user manager
export const fetchUsers = async (
    client: ApolloClient<NormalizedCacheObject>,
    dispatch: AppDispatch
) => {
    const GET_USERS = gql`
        query GetUsers {
            getUsers {
                id
                given_name
                family_name
                roles
            }
        }
    `

    try {
        const response = await client.query({
            query: GET_USERS,
        })
        return response.data.getUsers
    } catch (error) {
        console.error(error)
    }
}
