import React from 'react'
import './slider.css'

interface SliderProps {
    enabled: boolean
    setEnabled?: (enabled: boolean) => void
    enabledText?: string
    disableText?: string
}

const Slider: React.FC<SliderProps> = ({ enabled, setEnabled, enabledText, disableText }) => {
    const handleInputChange = () => {
        setEnabled && setEnabled(!enabled)
    }

    return (
        <div className="slider-parent">
            <div
                className={`slider-wrapper ${enabled ? 'enabled' : 'disabled'}`}
                onClick={handleInputChange}
            >
                {enabled && enabledText}
                <div className="slider-circle"></div>
                {!enabled && disableText}
            </div>
        </div>
    )
}

export { Slider }
