import './recorder.css'

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '../../data/store'
import { ImplementData, MachineData, SessionData, UserData } from '../../data/types'
import SnapshotManager from './snapshotManager'
import SessionTile from '../recordedSessions/sessionTile'

import { toast } from 'react-toastify'
import { Slider } from '../misc/slider'
interface JobTilesProps {
    machines: MachineData[]
    _implements: ImplementData[]
}

const Recorder = ({ machines, _implements }: JobTilesProps) => {
    const location = useLocation()
    const sessionListData = useSelector((state: RootState) => state.sessions.data)
    const userData = useSelector((state: RootState) => state.user.data)

    const [user, setUser] = useState<UserData | null>(null)

    const [sessionId, setSessionId] = useState('')

    const [isSessionActive, setIsSessionActive] = useState(false)
    const [activeSession, setActiveSession] = useState<SessionData | null>(null)
    const [isSessionOwner, setIsSessionOwner] = useState(false)
    const [isDisplayEventList, setIsDisplayEventList] = useState<boolean>(true)
    const [isLocalTime, setIsLocalTime] = useState<boolean>(false)
    const [scrollToEvent, setScrollToEvent] = useState('')
    const [isDescendingEventOrder, setisDescendingEventOrder] = useState<boolean>(false)

    const [previousSession, setPreviousSession] = useState<SessionData | null>(null)

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const eventId = params.get('scrollToEvent')
        setScrollToEvent(eventId || '')
    }, [location.search])

    useEffect(() => {
        if (userData) {
            setUser(userData[0])
        } else {
            setUser(null)
        }
    }, [userData])

    useEffect(() => {
        if (sessionListData) {
            const activeSessionLocal = sessionListData.find((session) => session.active === true)
            if (activeSessionLocal) {
                setActiveSession(activeSessionLocal)
                setSessionId(activeSessionLocal.id)
                setIsSessionActive(true)

                setIsSessionOwner(activeSessionLocal.owner.id === user?.id)
            } else {
                // If session id is not found then the user group changed. This necessitates a forced update
                const sessionFound = sessionListData.find((session) => session.id === sessionId)
                if (!sessionFound) {
                    setPreviousSession(null)
                } else if (activeSession) {
                    setPreviousSession(activeSession)
                }
                setIsSessionActive(false)
                setActiveSession(null)
                setIsSessionOwner(false)
            }
        }
    }, [sessionListData, activeSession, sessionId, user])

    const notifyStopSuccess = () =>
        toast.success('Session Saved!', {
            position: 'top-right',
            autoClose: 2000, // time in milliseconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { fontSize: '18px' },
        })

    const notifyEventReported = (classification: string) =>
        toast.success(`${classification} reported!`, {
            position: 'top-right',
            autoClose: 2000, // time in milliseconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { fontSize: '18px' },
        })

    return (
        <div className="field-recorder-wrapper">
            <div className="field-recorder">
                <SnapshotManager
                    notifyEventReported={notifyEventReported}
                    machines={machines}
                    _implements={_implements}
                    sessionId={sessionId}
                    notifyStopSuccess={notifyStopSuccess}
                    isSessionActive={isSessionActive}
                    isSessionOwner={isSessionOwner}
                    isPreviousSessionSet={previousSession === null}
                />
                {(activeSession || previousSession) && (
                    <div className="recorder-prev-session-wrapper">
                        <div className="toolbar">
                            <div className="tool">
                                <div className="label">Event List</div>
                                <Slider
                                    enabled={isDisplayEventList}
                                    setEnabled={setIsDisplayEventList}
                                />
                            </div>
                            <div className="tool">
                                <div className="label">Descending Event Order</div>
                                <Slider
                                    enabled={isDescendingEventOrder}
                                    setEnabled={setisDescendingEventOrder}
                                />
                            </div>
                            <div className="tool">
                                <div className="label">Local time</div>
                                <Slider enabled={isLocalTime} setEnabled={setIsLocalTime} />
                            </div>
                        </div>
                        <SessionTile
                            session={activeSession || previousSession}
                            enableDuplicate={true}
                            displayEventList={isDisplayEventList}
                            isUtc={!isLocalTime}
                            isDescending={isDescendingEventOrder}
                            scrollToEvent={scrollToEvent}
                            showSessionTools={false}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Recorder
