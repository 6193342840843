import React from 'react'
import { renderDescription } from '../recordedSessions/eventRenderer'

interface TooltipValue {
    bug: number
    event: number
    value: number
    description: {
        [key: string]: {
            [subKey: string]: {
                [subSubKey: string]: string
            }
        }
    }
}

interface TooltipPayload {
    stroke: string
    strokeWidth: number
    fill: string
    dataKey: string
    name: string
    color: string
    value: number
    payload: {
        timestamp: string
        longFormatTimestamp: string
        value: TooltipValue
    }
    hide: boolean
}

interface CustomTooltipProps {
    active?: boolean
    payload?: TooltipPayload[]
    label?: string
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                {renderDescription(payload[0].payload.value.description)}
                <div className="timestamp">{payload[0].payload.longFormatTimestamp}</div>
            </div>
        )
    }

    return null
}

export default CustomTooltip
