import { useAppDispatch } from '../../data/dispatchContext'
import { addMachineSuccess, deleteMachineSuccess } from '../../data/reducers/machines'
import {
    AddGenericFileEvent,
    DeviceData,
    MachineData,
    SessionData,
    SnapshotData,
} from '../../data/types'
import {
    addSessionSuccess,
    deleteSessionSuccess,
    addSnapshotSuccess,
    editSnapshotSuccess,
    stopSessionSuccess,
    deleteSnapshotSuccess,
    editSessionSuccess,
} from '../../data/reducers/sessions'
import {
    addOrUpdateDeviceFile,
    updateDeviceStatus,
    deleteDeviceFile,
    deleteDeviceAction,
} from '../../data/reducers/devices'

interface MachineEvent {
    action: string
    data: MachineData
}

interface SessionEvent {
    action: string
    data: SessionData | SnapshotData
}

interface DeviceEvent {
    action: string
    data: any
}

type AnyObject = { [key: string]: any } | any[]

const convertIds = (obj: AnyObject): AnyObject => {
    if (Array.isArray(obj)) {
        return obj.map(convertIds)
    } else if (obj && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc: { [key: string]: any }, key) => {
            if (key === '_id') {
                acc['id'] = convertIds(obj[key])
            } else {
                acc[key] = convertIds(obj[key])
            }
            return acc
        }, {})
    }
    return obj
}

export const EventManager = () => {
    const dispatch = useAppDispatch()

    const handleMachineEvent = (event: MachineEvent) => {
        if (event.action === 'addMachine' || event.action === 'editMachine') {
            const convertedData: MachineData = convertIds(event.data) as MachineData

            dispatch(addMachineSuccess(convertedData))
        } else if (event.action === 'deleteMachine') {
            const convertedData: MachineData = convertIds(event.data) as MachineData

            dispatch(deleteMachineSuccess(convertedData))
        }
    }

    const handleSessionEvent = (event: SessionEvent) => {
        if (event.action === 'addSession' || event.action === 'startSession') {
            const convertedData: SessionData = convertIds(event.data) as SessionData

            dispatch(addSessionSuccess(convertedData))
        } else if (event.action === 'editSession') {
            const convertedData: SessionData = convertIds(event.data) as SessionData

            dispatch(editSessionSuccess(convertedData))
        } else if (event.action === 'stopSession') {
            const convertedData: SessionData = convertIds(event.data) as SessionData

            dispatch(stopSessionSuccess(convertedData))
        } else if (event.action === 'deleteSession') {
            const convertedData: SessionData = convertIds(event.data) as SessionData

            dispatch(deleteSessionSuccess(convertedData))
        } else if (event.action === 'addSnapshot') {
            const convertedData: SnapshotData = convertIds(event.data) as SnapshotData

            dispatch(addSnapshotSuccess(convertedData))
        } else if (event.action === 'editSnapshot') {
            const convertedData: SnapshotData = convertIds(event.data) as SnapshotData

            dispatch(editSnapshotSuccess(convertedData))
        } else if (event.action === 'deleteSnapshot') {
            const convertedData: SnapshotData = convertIds(event.data) as SnapshotData

            dispatch(deleteSnapshotSuccess(convertedData))
        }
    }

    const handleDeviceEvent = (event: DeviceEvent) => {
        if (event.action === 'deviceUpdate') {
            const convertedData: DeviceData = convertIds(event.data) as DeviceData
            dispatch(updateDeviceStatus(convertedData))
        } else if (event.action === 'addDeviceFile' || event.action === 'partialDeviceFileUpload') {
            const convertedData: AddGenericFileEvent = convertIds(event.data) as AddGenericFileEvent
            dispatch(addOrUpdateDeviceFile(convertedData as AddGenericFileEvent))
        } else if (event.action === 'deviceFileDeleted') {
            const convertedData: { id: string; fileId: string } = convertIds(event.data) as {
                id: string
                fileId: string
            }
            dispatch(deleteDeviceFile(convertedData))
        } else if (event.action === 'deviceDeleted') {
            // Updated to handle the id payload
            const convertedData: { id: string } = convertIds(event.data) as { id: string }
            dispatch(deleteDeviceAction(convertedData))
        }
    }

    return { handleMachineEvent, handleSessionEvent, handleDeviceEvent }
}
