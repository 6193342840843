import { useState, ChangeEvent } from 'react'

interface JobFormProps {
    onCreateImplement: (name: string, description: string) => void
}

function ImplementForm({ onCreateImplement }: JobFormProps) {
    const [implementName, setImplementName] = useState<string>('')
    const [implementDescription, setImplementDescription] = useState<string>('')

    const handleImplementNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setImplementName(e.target.value)
    }

    const handleImplementDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setImplementDescription(e.target.value)
    }

    const handleCreateImplement = () => {
        onCreateImplement(implementName, implementDescription)
        setImplementName('')
        setImplementDescription('')
    }

    return (
        <div className="jobs-form">
            <header>Create New Implement</header>
            <div>
                <div>
                    <label>Implement Name:</label>
                    <input type="text" value={implementName} onChange={handleImplementNameChange} />
                </div>
                <div>
                    <label>Implement Description:</label>
                    <textarea
                        value={implementDescription}
                        onChange={handleImplementDescriptionChange}
                    />
                </div>
                <div>
                    <button onClick={handleCreateImplement}>Create Implement</button>
                </div>
            </div>
        </div>
    )
}

export default ImplementForm
