import './index.css'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useNetworkManager } from '../network/networkManager'
import { useCallback, useEffect, useRef, useState } from 'react'

import { RootState } from '../data/store'
import { useSelector } from 'react-redux'

import Header from '../components/header/header'
import Sidepanel from '../components/sidepanel/sidepanel'

import { useAuth0 } from '@auth0/auth0-react'

import { useSocketManager } from '../network/sockets/socketManager'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const MainWrapper = () => {
    const { user: userAPI, machines, _implements, sessions, groups, devices } = useNetworkManager()
    const hasFetchedUserData = useRef(false) // Ref to track if user data has been fetched
    const hasFetchedMachinesAndJobs = useRef(false) // Ref to track if machines and jobs data have been fetched
    const { user, getAccessTokenSilently } = useAuth0()
    const location = useLocation()
    const navigate = useNavigate()

    const userData = useSelector((state: RootState) => state.user.data)
    const userDataStatus = useSelector((state: RootState) => state.user.loading)

    const [groupId, setGroupId] = useState('')
    const [loading, setLoading] = useState(true) // State to track loading

    const { connect } = useSocketManager()
    useEffect(() => {
        const initialize = async () => {
            const token = await getAccessTokenSilently()
            connect(groupId, token)
        }

        initialize()

        // eslint-disable-next-line
    }, [groupId])

    useEffect(() => {
        if (!hasFetchedUserData.current && userAPI && machines && user) {
            userAPI.getUser().then(() => {
                hasFetchedUserData.current = true // Set the ref to true to prevent re-fetching
            })
        }
    }, [userAPI, machines, user, userData])

    const updateGroupData = useCallback(async () => {
        setLoading(true)
        // Await all fetch operations
        hasFetchedMachinesAndJobs.current = true // Set the ref to true to indicate fetching has triggered

        await Promise.all([
            machines.fetchMachines(),
            _implements.fetchImplements(),
            sessions.fetchSessions(),
            groups.getGroupProfile(),
            devices.getDevices(),
        ])

        setLoading(false) // Set loading state to false after data fetching is complete
    }, [machines, _implements, sessions, groups, devices])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const groupIdFromUrl = params.get('groupId')
        const loginParamsPresent = params.get('code') || params.get('state')

        const savedSearchParams = localStorage.getItem('searchParams')
        const savedPath = localStorage.getItem('savedPath')
        const savedGroupId = localStorage.getItem('groupId')

        const hasEncodedEntities = location.search.includes('&amp;')

        const decodeAndRedirectParams = () => {
            console.log('decoded')
            const decodedParams = location.search.replace(/&amp;/g, '&')
            if (hasEncodedEntities) {
                navigate({ search: decodedParams }, { replace: true })
            } else {
                return true
            }
        }

        const clearLoginParams = () => {
            params.delete('code')
            params.delete('state')
            navigate({ search: params.toString() }, { replace: true })
        }

        const restoreSavedSearch = (savedPath: string, savedSearchParams: string) => {
            // Restore path and search params if available and remove from local storage
            localStorage.removeItem('savedPath')
            localStorage.removeItem('searchParams')
            navigate(
                {
                    pathname: savedPath,
                    search: savedSearchParams,
                },
                { replace: true }
            )
        }

        const handleGroupIdUrl = (UrlId: string) => {
            const isValidGroupId = userData[0].groups.some((group) => group.id === UrlId)

            if (!isValidGroupId) {
                toast.error(`You don't belong to this group. Please request access.`)
                navigate('/', { replace: true })
            } else if (UrlId !== groupId) {
                // Place in local storage for Apollo client
                localStorage.setItem('groupId', UrlId)
                setGroupId(UrlId)
                hasFetchedMachinesAndJobs.current = false
            }
        }

        const restoreIdFromStorage = (groupId: string) => {
            const params = new URLSearchParams(location.search)
            params.set('groupId', groupId)
            navigate({ search: params.toString() }, { replace: true })
        }

        const setDefaultId = () => {
            // Update URL search param instead of local storage
            const params = new URLSearchParams(location.search)
            params.set('groupId', userData[0].groups[0].id)
            navigate({ search: params.toString() }, { replace: true })
        }

        if (hasEncodedEntities) {
            decodeAndRedirectParams()
        } else if (loginParamsPresent) {
            clearLoginParams()
        } else if (savedSearchParams && savedPath) {
            restoreSavedSearch(savedPath.toString(), savedSearchParams.toString())
        } else if (userData.length > 0 && userData[0].groups && userData[0].groups.length > 0) {
            if (groupIdFromUrl) {
                handleGroupIdUrl(groupIdFromUrl)
            } else {
                if (savedGroupId) {
                    restoreIdFromStorage(savedGroupId.toString())
                } else {
                    setDefaultId()
                }
            }
        }
    }, [location.search, userData, userDataStatus, groupId, navigate])

    useEffect(() => {
        if (
            groupId &&
            !hasFetchedMachinesAndJobs.current &&
            Array.isArray(userData) &&
            userData.length > 0 &&
            userData[0].id &&
            !userDataStatus
        ) {
            updateGroupData()
        }
    }, [userData, userDataStatus, updateGroupData, groupId])

    return (
        <div id="base">
            <Header />
            <div id="main-container">
                {loading ? (
                    <div>Loading...</div> // Loading indicator
                ) : (
                    <>
                        <ToastContainer />
                        <Sidepanel location={location.pathname} />
                        <div id="main-container-content">
                            <Outlet />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default MainWrapper
