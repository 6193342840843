import { useSelector } from 'react-redux'
import { RootState } from '../data/store'

import UserManager from '../components/userManagement/userManager'
import { useNetworkManager } from '../network/networkManager'
import { useEffect, useState } from 'react'

import { UserData } from '../data/types'

function App() {
    const { groups: groupsAPI, user: usersAPI } = useNetworkManager()
    const groups = useSelector((state: RootState) => state.groups.data)

    const [users, setUsers] = useState<UserData[]>([])

    useEffect(() => {
        const getUsers = async () => {
            try {
                // Type assertion to handle the possible void return
                const userData = (await usersAPI.getUsers_management()) as UserData[] | void
                if (userData && userData.length > 0) {
                    setUsers(userData)
                } else {
                    setUsers([]) // Set to an empty array if userData is void or empty
                }
            } catch (error) {
                console.error('Error fetching users:', error)
                setUsers([]) // Set to an empty array if an error occurs
            }
        }

        groupsAPI.getGroups()
        getUsers()
        // eslint-disable-next-line
    }, [])

    return <UserManager groups={groups} users={users} />
}

export default App
