import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, ImplementData, ErrorPayload } from '../types'
import { sanitizeImplementsData, sanitizeImplementData } from '../../components/utils/dataSanitizer'

const initialState: DataState<ImplementData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

const dataSlice = createSlice({
    name: 'implements',
    initialState,
    reducers: {
        // Start & Error handlers
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },

        // Result handlers
        fetchImplementsSuccess(state, action: PayloadAction<ImplementData[]>) {
            state.loading = false
            state.data = sanitizeImplementsData(action.payload)
            state.error.message = ''
        },
        addImplementSuccess(state, action: PayloadAction<ImplementData>) {
            state.loading = false
            const implementIndex = state.data.findIndex(
                (implement) => implement.id === action.payload.id
            )
            if (implementIndex === -1) {
                state.data.push(action.payload) // Add new implement
            } else {
                state.data[implementIndex] = action.payload // Update existing implement
            }
            state.error.message = ''
        },
        editImplementSuccess(state, action: PayloadAction<ImplementData>) {
            state.loading = false
            const implementIndex = state.data.findIndex(
                (implement) => implement.id === action.payload.id
            )
            if (implementIndex !== -1) {
                state.data[implementIndex] = sanitizeImplementData(action.payload)
            }
            state.error.message = ''
        },
        deleteImplementSuccess(state, action: PayloadAction<ImplementData>) {
            state.loading = false
            state.data = state.data.filter((implement) => implement.id !== action.payload.id)
            state.error.message = ''
        },
    },
})

export const {
    fetchDataRequest,
    fetchDataFailure,
    fetchImplementsSuccess,
    addImplementSuccess,
    editImplementSuccess,
    deleteImplementSuccess,
} = dataSlice.actions
export default dataSlice.reducer
