import { ApolloClient } from '@apollo/client'
import { NormalizedCacheObject } from '@apollo/client'

import { AsyncAction } from './types'

import { AppDispatch } from '../data/store'
import { CreateApolloClient } from './apolloClient'
import { useAppDispatch } from '../data/dispatchContext'

import * as machineActions from './graphqlActions/machinesActions'
import * as _implementActions from './graphqlActions/implementsActions'
import * as userActions from './graphqlActions/userActions'
import * as sessionActions from './graphqlActions/sessionActions'
import * as groupActions from './graphqlActions/groupsActions'
import * as deviceActions from './graphqlActions/devicesActions'

import { useAuth0 } from '@auth0/auth0-react'

// Determine base URL depending on the environment
const baseURL =
    window.location.hostname === 'localhost'
        ? 'http://localhost:8000'
        : 'https://fieldeventreporter.com:8000'

const withClientAndDispatch = (
    client: ApolloClient<NormalizedCacheObject>,
    action: AsyncAction,
    dispatch: AppDispatch
) => {
    return (...args: any[]) => {
        return action(client, dispatch, ...args)
    }
}

export const useNetworkManager = () => {
    const { getAccessTokenSilently } = useAuth0()

    const dispatch = useAppDispatch()
    const machinesClient = CreateApolloClient(`${baseURL}/machines`, getAccessTokenSilently)
    const _implementsClient = CreateApolloClient(`${baseURL}/implements`, getAccessTokenSilently)
    const userClient = CreateApolloClient(`${baseURL}/users`, getAccessTokenSilently)
    const sessionsClient = CreateApolloClient(`${baseURL}/sessions`, getAccessTokenSilently)
    const groupsClient = CreateApolloClient(`${baseURL}/groups`, getAccessTokenSilently)
    const devicesClient = CreateApolloClient(`${baseURL}/devices`, getAccessTokenSilently)

    return {
        machines: {
            fetchMachines: withClientAndDispatch(
                machinesClient,
                machineActions.fetchMachines,
                dispatch
            ),
            addMachine: withClientAndDispatch(machinesClient, machineActions.addMachine, dispatch),
            editMachine: withClientAndDispatch(
                machinesClient,
                machineActions.editMachine,
                dispatch
            ),
            deleteMachine: withClientAndDispatch(
                machinesClient,
                machineActions.deleteMachine,
                dispatch
            ),
        },
        _implements: {
            fetchImplements: withClientAndDispatch(
                _implementsClient,
                _implementActions.fetchImplements,
                dispatch
            ),
            addImplement: withClientAndDispatch(
                _implementsClient,
                _implementActions.addImplement,
                dispatch
            ),
            editImplement: withClientAndDispatch(
                _implementsClient,
                _implementActions.editImplement,
                dispatch
            ),
            deleteImplement: withClientAndDispatch(
                _implementsClient,
                _implementActions.deleteImplement,
                dispatch
            ),
        },
        user: {
            getUser: withClientAndDispatch(userClient, userActions.fetchUser, dispatch),
            getUsers_management: withClientAndDispatch(
                userClient,
                userActions.fetchUsers,
                dispatch
            ),
        },
        groups: {
            getGroups: withClientAndDispatch(groupsClient, groupActions.fetchGroups, dispatch),
            addGroup: withClientAndDispatch(groupsClient, groupActions.addGroup, dispatch),
            addUser: withClientAndDispatch(groupsClient, groupActions.addUser, dispatch),
            removeUser: withClientAndDispatch(groupsClient, groupActions.removeUser, dispatch),
            getGroupProfile: withClientAndDispatch(
                groupsClient,
                groupActions.getGroupProfile,
                dispatch
            ),
        },
        sessions: {
            fetchSessions: withClientAndDispatch(
                sessionsClient,
                sessionActions.fetchSessions,
                dispatch
            ),
            addSession: withClientAndDispatch(sessionsClient, sessionActions.addSession, dispatch),
            editSession: withClientAndDispatch(
                sessionsClient,
                sessionActions.editSession,
                dispatch
            ),
            stopSession: withClientAndDispatch(
                sessionsClient,
                sessionActions.stopSession,
                dispatch
            ),
            deleteSession: withClientAndDispatch(
                sessionsClient,
                sessionActions.deleteSession,
                dispatch
            ),
            addSnapshot: withClientAndDispatch(
                sessionsClient,
                sessionActions.addSnapshot,
                dispatch
            ),
            editSnapshot: withClientAndDispatch(
                sessionsClient,
                sessionActions.editSnapshot,
                dispatch
            ),
            deleteSnapshot: withClientAndDispatch(
                sessionsClient,
                sessionActions.deleteSnapshot,
                dispatch
            ),
        },
        devices: {
            getDevices: withClientAndDispatch(devicesClient, deviceActions.fetchDevices, dispatch),
            updateDevice: withClientAndDispatch(
                devicesClient,
                deviceActions.updateDevice,
                dispatch
            ),
            deleteDeviceFile: withClientAndDispatch(
                devicesClient,
                deviceActions.deleteDeviceFile,
                dispatch
            ),
            downloadDeviceFile: withClientAndDispatch(
                devicesClient,
                deviceActions.downloadDeviceFile,
                dispatch
            ),
            commandDevice: withClientAndDispatch(
                devicesClient,
                deviceActions.commandDevice,
                dispatch
            ),
            deleteDevice: withClientAndDispatch(
                devicesClient,
                deviceActions.deleteDevice,
                dispatch
            ),
        },
    }
}
