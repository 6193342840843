import { ApolloClient, InMemoryCache, NormalizedCacheObject, HttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

// Define the type for getAccessTokenSilently function
type GetAccessTokenSilently = () => Promise<string>

export const CreateApolloClient = (
    uri: string,
    getAccessTokenSilently: GetAccessTokenSilently
): ApolloClient<NormalizedCacheObject> => {
    const authLink = setContext(async (_, { headers }) => {
        const token = await getAccessTokenSilently()
        const groupId = localStorage.getItem('groupId') // Retrieve groupId from local storage
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
                group_id: groupId || '', // Add groupId to headers
            },
        }
    })

    const httpLink = new HttpLink({
        uri: uri,
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message }) => alert(message))
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`)
        }
    })

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([errorLink, authLink, httpLink]),
        connectToDevTools: true,
    })

    return client
}
