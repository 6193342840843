import { useEffect, useRef, MutableRefObject } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faSlash } from '@fortawesome/free-solid-svg-icons'
import { formatDistance } from 'date-fns'

interface CrossedOffIconProps {
    icon: IconDefinition
}

export const CrossedOffIcon = ({ icon }: CrossedOffIconProps) => {
    return (
        <span style={{ position: 'relative', display: 'inline-block' }}>
            <FontAwesomeIcon icon={icon} />
            <FontAwesomeIcon
                icon={faSlash}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    color: 'grey',
                    pointerEvents: 'none',
                }}
            />
        </span>
    )
}

export const FormatDate = (isoString: string | undefined, useUTC: boolean = false): string => {
    if (!isoString) return 'N/A'
    const date = new Date(isoString)
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: useUTC ? 'UTC' : undefined,
        timeZoneName: 'short',
        hourCycle: useUTC ? 'h23' : 'h12',
    }).format(date)
}

interface TimestampDeltaProps {
    previousTimestamp: string
    currentTimestamp: string
    limit: number // Limit in milliseconds
}

export const TimestampDelta: React.FC<TimestampDeltaProps> = ({
    previousTimestamp,
    currentTimestamp,
    limit,
}) => {
    const previousDate = new Date(previousTimestamp)
    const currentDate = new Date(currentTimestamp)

    const deltaMilliseconds = currentDate.getTime() - previousDate.getTime()

    if (deltaMilliseconds < limit) {
        return null
    }

    const delta = formatDistance(previousDate, currentDate)

    return <div className="snapshot-delta">{delta}</div>
}

export const useResizeObserver = (
    callback: ResizeObserverCallback
): MutableRefObject<HTMLDivElement | null> => {
    const observerRef = useRef<ResizeObserver | null>(null)
    const elementRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const element = elementRef.current
        if (element) {
            observerRef.current = new ResizeObserver(callback)
            observerRef.current.observe(element)
        }

        return () => {
            if (observerRef.current && element) {
                observerRef.current.unobserve(element)
            }
        }
    }, [callback])

    return elementRef
}

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

interface TextWrapperProps {
    text: string
    charLimit: number
}

const TextWrapper: React.FC<TextWrapperProps> = ({ text, charLimit }) => {
    const wrapText = (text: string, charLimit: number) => {
        const regex = new RegExp(`.{1,${charLimit}}`, 'g')
        const segments = text.match(regex) || []

        return segments.map((segment) => {
            // const padding = '&nbsp;'.repeat(charLimit - segment.length)
            return segment //+ padding
        })
    }

    return (
        <>
            {wrapText(text, charLimit).map((segment, index) => (
                <div key={index} dangerouslySetInnerHTML={{ __html: segment }} />
            ))}
        </>
    )
}

export { TextWrapper }
