import { RootState } from '../data/store'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import SessionTiles from '../components/recordedSessions/sessionTiles'

function RecordedSessions() {
    const sessionsData = useSelector((state: RootState) => state.sessions.data)
    const location = useLocation()

    const [sessions, setSessions] = useState(sessionsData)
    const [scrollToEvent, setScrollToEvent] = useState('')

    useEffect(() => {
        setSessions(sessionsData)
    }, [sessionsData])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const eventId = params.get('scrollToEvent')
        setScrollToEvent(eventId || '')
    }, [location.search])

    return <SessionTiles sessions={sessions} scrollToEvent={scrollToEvent} />
}

export default RecordedSessions
