import { useState, useEffect, useCallback } from 'react'
import { createSocket } from './socket'
import { EventManager } from './events'
import { Socket } from 'socket.io-client'

interface SocketManagerReturn {
    isConnected: boolean
    connect: (groupId: string, token: string) => void
    socket: Socket | null
}

export function useSocketManager(): SocketManagerReturn {
    const { handleMachineEvent, handleSessionEvent, handleDeviceEvent } = EventManager()
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [socket, setSocket] = useState<Socket | null>(null)

    const connect = useCallback(
        (groupId: string, token: string) => {
            const initializeSocket = async () => {
                if (socket?.connected) {
                    socket.disconnect()
                }

                const socketio = createSocket(token, groupId || '')
                setSocket(socketio)

                socketio.on('connect', () => {
                    setIsConnected(true)
                })

                socketio.on('disconnect', () => {
                    setIsConnected(false)
                })

                socketio.on('machines', handleMachineEvent)
                socketio.on('sessions', handleSessionEvent)
                socketio.on('devices', handleDeviceEvent)
            }

            if (groupId) {
                initializeSocket()
            }
        },
        [handleMachineEvent, handleSessionEvent, handleDeviceEvent, socket]
    )

    useEffect(() => {
        return () => {
            if (socket) {
                socket.off('connect')
                socket.off('disconnect')
                socket.off('jobs')
                socket.off('machines')
                socket.off('sessions')
                socket.off('devices')

                socket.disconnect()
            }
        }
    }, [socket])

    return { isConnected, connect, socket }
}
