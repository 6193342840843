import './recorder.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCircle } from '@fortawesome/free-solid-svg-icons'
import { useState, useRef, useEffect, FC } from 'react'
import { useNetworkManager } from '../../network/networkManager'
import { useSessionDataHook } from './sessionDataHelpers'
import { ImplementData, MachineData, ReporterProfile } from '../../data/types'
import { RootState } from '../../data/store'
import { useSelector } from 'react-redux'

interface RecorderButtonsProps {
    machines: MachineData[]
    _implements: ImplementData[]
    sessionId: string
    notifyStopSuccess: () => void
    isSessionActive: boolean
    isSessionOwner: boolean
}

const RecorderButtons: FC<RecorderButtonsProps> = ({
    machines,
    _implements,
    sessionId,
    notifyStopSuccess,
    isSessionActive,
    isSessionOwner,
}) => {
    const {
        sessions: sessionsAPI,
        machines: machinesAPI,
        _implements: implementsAPI,
    } = useNetworkManager()

    const { currentSoftware } = useSessionDataHook(sessionId)

    const [showJobDropdown, setShowJobDropdown] = useState(false)
    const [selectedVehicle, setSelectedVehicle] = useState<MachineData | null>(null)

    const [showImplementDropdown, setShowImplementDropdown] = useState(false)
    const [selectedImplement, setSelectedImplement] = useState<ImplementData | null>(null)

    const formRef = useRef<HTMLDivElement>(null)
    const implementFormRef = useRef<HTMLDivElement>(null)

    const [sessionLoading, setSessionLoading] = useState<boolean>(false)

    const [machineSoftwareVersions, setMachineSoftwareVersions] = useState<{
        [key: string]: string
    }>({})
    const [implementSoftwareVersions, setImplementSoftwareVersions] = useState<{
        [key: string]: string
    }>({})

    const [sessionTitle, setSessionTitle] = useState<string>('')

    const groupData = useSelector((state: RootState) => state.groups.data)
    const [reporterProfile, setReporterProfile] = useState<ReporterProfile | null>(null)

    useEffect(() => {
        if (groupData && groupData.length > 0) {
            setReporterProfile(groupData[0].reporterProfile || null)
        }
    }, [groupData])

    useEffect(() => {
        if (showJobDropdown && !isSessionActive && formRef.current) {
            const formHeight = formRef.current.scrollHeight + 'px'
            formRef.current.style.maxHeight = formHeight
        } else if (formRef.current) {
            formRef.current.style.maxHeight = '0'
        }
    }, [showJobDropdown, isSessionActive])

    const toggleForm = () => {
        if (showJobDropdown) {
            if (formRef.current) {
                formRef.current.style.maxHeight = '0'
            }
            setTimeout(() => setShowJobDropdown(false), 500) // 500ms delay
        } else {
            setShowJobDropdown(true)
        }
    }

    const setJob = (machine: MachineData) => {
        setSelectedVehicle(machine)
        setShowJobDropdown(false)
        // Ensure job.machine.softwareVersions is a non-empty array
        if (Array.isArray(machine.softwareVersions) && machine.softwareVersions.length > 0) {
            const initialMachineSoftwareVersions = machine.softwareVersions.reduce(
                (acc, version) => ({
                    ...acc,
                    [version.name]: version.version,
                }),
                {}
            )
            setMachineSoftwareVersions(initialMachineSoftwareVersions)
        } else {
            // Reset versions if none are provided by the machine in the job
            setMachineSoftwareVersions({})
        }
    }

    const setImplement = (implement: ImplementData) => {
        setSelectedImplement(implement)
        setShowImplementDropdown(false)

        // Ensure job.machine.softwareVersions is a non-empty array
        if (Array.isArray(implement.softwareVersions) && implement.softwareVersions.length > 0) {
            const initialImplementSoftwareVersions = implement.softwareVersions.reduce(
                (acc, version) => ({
                    ...acc,
                    [version.name]: version.version,
                }),
                {}
            )
            setImplementSoftwareVersions(initialImplementSoftwareVersions)
        } else {
            // Reset versions if none are provided by the machine in the job
            setImplementSoftwareVersions({})
        }
    }

    useEffect(() => {
        if (showImplementDropdown && !isSessionActive && implementFormRef.current) {
            const formHeight = implementFormRef.current.scrollHeight + 'px'
            implementFormRef.current.style.maxHeight = formHeight
        } else if (implementFormRef.current) {
            implementFormRef.current.style.maxHeight = '0'
        }
    }, [showImplementDropdown, isSessionActive])

    const toggleImplementForm = () => {
        if (showImplementDropdown) {
            if (implementFormRef.current) {
                implementFormRef.current.style.maxHeight = '0'
            }
            setTimeout(() => setShowImplementDropdown(false), 500) // 500ms delay
        } else {
            setShowImplementDropdown(true)
        }
    }

    const handleSession = async () => {
        if (!isSessionActive) {
            if (selectedVehicle && selectedImplement) {
                const machineSoftwareVersionsArray = Object.entries(machineSoftwareVersions).map(
                    ([name, version]) => ({ name, version })
                )

                const implementSoftwareVersionsArray = Object.entries(
                    implementSoftwareVersions
                ).map(([name, version]) => ({ name, version }))

                setSessionLoading(true)

                await machinesAPI.editMachine(
                    selectedVehicle.id,
                    selectedVehicle.name,
                    selectedVehicle.description,
                    machineSoftwareVersionsArray
                )
                await implementsAPI.editImplement(
                    selectedImplement.id,
                    selectedImplement.name,
                    selectedImplement.description,
                    implementSoftwareVersionsArray
                )

                const softwareVersions = {
                    machines: machineSoftwareVersionsArray,
                    _implements: implementSoftwareVersionsArray,
                }
                await sessionsAPI.addSession(
                    selectedVehicle.id,
                    selectedImplement.id,
                    sessionTitle,
                    softwareVersions
                )

                setSessionLoading(false)
            } else {
                alert('No Machine and/or Vehicle selected')
            }
        } else if (sessionId) {
            setSessionLoading(true)
            await sessionsAPI.stopSession(sessionId, currentSoftware)
            setSessionLoading(false)
            notifyStopSuccess()
        } else {
            alert('no session ID')
        }
    }

    const handleSoftwareVersionChange = (type: string, software: string, version: string) => {
        if (type === 'machine') {
            setMachineSoftwareVersions((prevVersions) => ({
                ...prevVersions,
                [software]: version,
            }))
        } else if (type === '_implement') {
            setImplementSoftwareVersions((prevVersions) => ({
                ...prevVersions,
                [software]: version,
            }))
        }
    }

    const SessionParticipant = () => {
        return (
            <div className="session-participant">
                <div className="status">
                    <div className="title">Active</div>
                    <div className="indicator">
                        <FontAwesomeIcon icon={faCircle} />
                    </div>
                </div>
                {sessionLoading ? (
                    <div className="controls">
                        <div style={{ display: 'flex', padding: '20px' }}>
                            <span className="spinner"></span>
                        </div>
                    </div>
                ) : (
                    isSessionOwner && (
                        <div className="controls">
                            <button onClick={handleSession} className="form-button">
                                Stop Session
                            </button>
                        </div>
                    )
                )}
            </div>
        )
    }

    const VehicleSelectionDropdown = () => {
        return (
            <div className="job-selection-dropdown">
                <div style={{ fontWeight: '600' }}>Vehicle</div>
                <button
                    className={
                        !isSessionActive
                            ? 'record icon-button configure'
                            : 'record icon-button configure disabled'
                    }
                    onClick={toggleForm}
                >
                    <span></span>
                    <span>{selectedVehicle ? selectedVehicle.name : 'Select a Vehicle'}</span>
                    {!isSessionActive ? (
                        <span style={{ textAlign: 'right' }}>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                    ) : (
                        <span></span>
                    )}
                </button>
                <div
                    ref={formRef}
                    className={
                        showJobDropdown && !isSessionActive ? 'form-slide expanded' : 'form-slide'
                    }
                >
                    {machines.map((machine) => (
                        <button
                            key={machine.id}
                            className="icon-button configure"
                            onClick={() => setJob(machine)}
                        >
                            {machine.name}
                        </button>
                    ))}
                </div>
            </div>
        )
    }

    const ImplementSelectionDropDown = () => {
        return (
            <div className="job-selection-dropdown">
                <div style={{ fontWeight: '600' }}>Implement</div>
                <button
                    className={
                        !isSessionActive
                            ? 'record icon-button configure'
                            : 'record icon-button configure disabled'
                    }
                    onClick={toggleImplementForm}
                >
                    <span></span>
                    <span>{selectedImplement ? selectedImplement.name : 'Select a Implement'}</span>
                    {!isSessionActive ? (
                        <span style={{ textAlign: 'right' }}>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                    ) : (
                        <span></span>
                    )}
                </button>
                <div
                    ref={implementFormRef}
                    className={
                        showImplementDropdown && !isSessionActive
                            ? 'form-slide expanded'
                            : 'form-slide'
                    }
                >
                    {_implements.map((implement) => (
                        <button
                            key={implement.id}
                            className="icon-button configure"
                            onClick={() => setImplement(implement)}
                        >
                            {implement.name}
                        </button>
                    ))}
                </div>
            </div>
        )
    }

    const softwareItem = (type: string, software: string) => {
        const softwareVersions =
            type === 'machine' ? machineSoftwareVersions : implementSoftwareVersions

        return (
            <div className="software-item" key={software}>
                <div className="title">{software}</div>
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={softwareVersions[software] || ''}
                        onChange={(e) =>
                            handleSoftwareVersionChange(type, software, e.target.value)
                        }
                        placeholder="Enter version"
                    />
                </div>
            </div>
        )
    }

    const sessionTitleHelper = () => {
        return (
            <>
                <div style={{ fontWeight: '600' }}>Session Title:</div>
                <div className="session-title">
                    <input
                        type="text"
                        value={sessionTitle}
                        onChange={(e) => setSessionTitle(e.target.value)}
                        placeholder="Enter title"
                    />
                </div>
            </>
        )
    }

    const startSessionController = () => {
        return (
            <div className="start-session-controller">
                <VehicleSelectionDropdown />
                <ImplementSelectionDropDown />
                {selectedVehicle && selectedImplement && (
                    <>
                        {sessionTitleHelper()}
                        <div>
                            <span style={{ fontWeight: '800' }}>Vehicle:</span>{' '}
                            {selectedVehicle.name}
                        </div>
                        <div className="software-versions">
                            <div style={{ fontWeight: '800' }}>Vehicle Software Versions:</div>
                            {(
                                reporterProfile?.loggingConfiguration?.softwareItems?.machines || []
                            ).map((software: string) => {
                                return softwareItem('machine', software)
                            })}
                            <div style={{ fontWeight: '800' }}>Implement Software Versions:</div>
                            {(
                                reporterProfile?.loggingConfiguration?.softwareItems?._implements ||
                                []
                            ).map((software: string) => {
                                return softwareItem('_implement', software)
                            })}
                        </div>
                        <button className="form-button" onClick={handleSession}>
                            {sessionLoading ? (
                                <>
                                    <div style={{ display: 'flex', padding: '20px' }}>
                                        <span className="spinner"></span>
                                    </div>
                                    <div className="start-session-title">Starting...</div>
                                </>
                            ) : (
                                <div className="start-session-title">Start Session</div>
                            )}
                        </button>
                    </>
                )}
            </div>
        )
    }

    return isSessionActive ? (
        <div className="session-participant-wrapper">
            <SessionParticipant />
        </div>
    ) : (
        <div className="record-button-group">{startSessionController()}</div>
    )
}

const RecordButtonLeftSpacer = () => {
    return <div className="session-participant-spacer"></div>
}

export { RecorderButtons, RecordButtonLeftSpacer }
