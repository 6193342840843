import { useNavigate, To, useLocation } from 'react-router-dom'
import './sidepanel.css'
import { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBars,
    faAngleDoubleLeft,
    faHome,
    faTractor,
    faHistory,
    faUserGroup,
    faTrailer,
    faHeartPulse,
    faMicrochip,
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../data/store'

interface SidepanelProps {
    location: string
}

const Sidepanel = ({ location }: SidepanelProps) => {
    const navigate = useNavigate()
    const currentLocation = useLocation()
    const [sideOpen, setSidebarOpen] = useState(
        localStorage.getItem('sidebar_open') === 'open' && window.innerWidth > 800 ? true : false
    )

    const [isAdmin, setIsAdmin] = useState(false)
    const [isBeta, setIsBeta] = useState(false)

    const userData = useSelector((state: RootState) => state.user.data)

    const setSidebar = (open: boolean) => {
        if (open && window.innerWidth <= 800) {
            return
        }
        setSidebarOpen(open)
        localStorage.setItem('sidebar_open', open ? 'open' : 'closed')
    }

    const sidepanelRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const [sidepanelWidth, setSidepanelWidth] = useState(0)
    const [buttonWidth, setButtonWidth] = useState(0)

    useEffect(() => {
        if (sidepanelRef.current) {
            setSidepanelWidth(sidepanelRef.current.offsetWidth)
        }
        if (buttonRef.current) {
            setButtonWidth(buttonRef.current.offsetWidth)
        }
    }, [sideOpen])

    useEffect(() => {
        if (userData && userData[0]) setIsAdmin(userData[0].roles.includes('admin'))
        if (userData && userData[0]) setIsBeta(userData[0].roles.includes('beta'))
    }, [userData])

    const handleNavigation = (path: To) => {
        const params = new URLSearchParams(currentLocation.search)
        const groupId = params.get('groupId')

        if (groupId) {
            // Preserve the groupId parameter
            navigate(`${path}?groupId=${groupId}`)
        } else {
            navigate(path)
        }
    }

    const isActive = (path: string) => (location === path ? 'link active' : 'link')

    return (
        <div
            style={{ marginLeft: sideOpen ? '0px' : -sidepanelWidth + buttonWidth }}
            ref={sidepanelRef}
            id="sidepanel-wrapper"
        >
            <div id="sidepanel">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        className="collapse link"
                        onClick={() => setSidebar(!sideOpen)}
                        ref={buttonRef}
                    >
                        {!sideOpen ? (
                            <FontAwesomeIcon icon={faBars} className="close-icon" />
                        ) : (
                            <FontAwesomeIcon icon={faAngleDoubleLeft} className="close-icon" />
                        )}
                    </button>
                </div>
                <button className={isActive('/')} onClick={() => handleNavigation('/')}>
                    <div>{sideOpen && <span>Home</span>}</div>
                    <div>
                        {!sideOpen && <FontAwesomeIcon icon={faHome} className="close-icon" />}
                    </div>
                </button>
                <button
                    className={isActive('/recorded-sessions')}
                    onClick={() => handleNavigation('/recorded-sessions')}
                >
                    <div>{sideOpen && <span>Sessions</span>}</div>
                    <div>
                        {!sideOpen && <FontAwesomeIcon icon={faHistory} className="close-icon" />}
                    </div>
                </button>
                <button
                    className={isActive('/vehicles')}
                    onClick={() => handleNavigation('/vehicles')}
                >
                    <div>{sideOpen && <span>Vehicles</span>}</div>
                    <div>
                        {!sideOpen && <FontAwesomeIcon icon={faTractor} className="close-icon" />}
                    </div>
                </button>
                <button
                    className={isActive('/implements')}
                    onClick={() => handleNavigation('/implements')}
                >
                    <div>{sideOpen && <span>Implements</span>}</div>
                    <div>
                        {!sideOpen && <FontAwesomeIcon icon={faTrailer} className="close-icon" />}
                    </div>
                </button>
                {isBeta && (
                    <button
                        className={isActive('/devices')}
                        onClick={() => handleNavigation('/devices')}
                    >
                        <div>{sideOpen && <span>Devices</span>}</div>
                        <div>
                            {!sideOpen && (
                                <FontAwesomeIcon icon={faMicrochip} className="close-icon" />
                            )}
                        </div>
                    </button>
                )}

                <button
                    className={isActive('/reports')}
                    onClick={() => handleNavigation('/reports')}
                >
                    <div>{sideOpen && <span>Reports</span>}</div>
                    <div>
                        {!sideOpen && (
                            <FontAwesomeIcon icon={faHeartPulse} className="close-icon" />
                        )}
                    </div>
                </button>
                {isAdmin && (
                    <button
                        className={isActive('/user-management')}
                        onClick={() => handleNavigation('/user-management')}
                    >
                        <div>{sideOpen && <span>User Management</span>}</div>
                        <div>
                            {!sideOpen && (
                                <FontAwesomeIcon icon={faUserGroup} className="close-icon" />
                            )}
                        </div>
                    </button>
                )}
            </div>
        </div>
    )
}

export default Sidepanel
